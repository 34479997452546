import store from '@/store'
import { combineRoutes } from '@/services/utils'

export default function(to, from, next) {
  let gym = store.state.gym
  let gymDefaultType = gym.gym_type == 'climbing' ? 'routes' : 'boulders'
  let climbsType = to.params.climbsType || 'climbs'

  let noTypeSpecified = climbsType == 'climbs'
  let notGymDefaultType = climbsType != gymDefaultType
  let noClimbsOfType = !(gym[`nr_of_${climbsType}`] > 0)
  if (noTypeSpecified || (notGymDefaultType && noClimbsOfType && !store.state.isAdmin)) {
    let routeUpdate = {
      params: { climbsType: gymDefaultType },
      replace: true,
    }
    return next(combineRoutes(to, routeUpdate))
  }
  store.commit('setClimbType', climbsType.slice(0, -1))
  next()
}
