<template>
  <v-row align="end" justify="space-between" no-gutters class="tl-grade-votes flex-nowrap">
    <v-fade-transition>
      <span v-if="loading" class="tl-grade-votes__overlay" v-t="'generic.loading'" />
    </v-fade-transition>
    <v-fade-transition>
      <span v-if="!loading && !votes.length" class="tl-grade-votes__overlay" v-t="'climbs.log.stats.votesEmpty'" />
    </v-fade-transition>
    <v-col v-for="vote in parsedVotesReduced" :key="vote.label" class="tl-grade-votes__item align-end">
      <v-row layout="column" align="end" justify="center" no-gutters class="fill-height">
        <v-col class="tl-grade-votes__bar" :style="{ height: vote.percent }">
          <div class="tl-grade-votes__count text-no-wrap font-weight-bold">{{ vote.count }}</div>
        </v-col>
        <div class="tl-grade-votes__axis" />
        <div class="tl-grade-votes__label text-no-wrap" v-html="vote.label" />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { round } from '@/services/utils'
import Grade from '@/services/grades/Grade'

export default {
  props: {
    votes: { type: Array, default: () => [] },
    grade: { type: Number, default: 2.0 },
    loading: { type: Boolean, default: false },
  },
  computed: {
    ...mapState(['climbType']),
    parsedVotes() {
      if (!this.votes.length) return [{ grade: new Grade(this.grade), count: 0 }]
      return this.votes
        .map(vote => {
          if (!(vote.grade instanceof Grade)) vote.grade = new Grade(vote.grade)
          return vote
        })
        .reduce((acc, vote) => {
          // Remove duplicate votes:
          let presentVote = acc.find(accVote => accVote.grade.valueRounded == vote.grade.valueRounded)
          presentVote ? (presentVote.count += vote.count) : acc.push(vote)
          return acc
        }, [])
    },
    parsedVotesReduced() {
      let maxVote = this.parsedVotes.reduce((max, vote) => (vote.count > max.count ? vote : max))
      let maxVoteIndex = maxVote.grade.idxRound
      return Grade.systemGrades().reduce((acc, grade, index) => {
        if (index < maxVoteIndex - 4 || index > maxVoteIndex + 4) return acc
        let vote = this.parsedVotes.find(vote => vote.grade.valueRounded == grade.valueRounded)
        let count = vote ? vote.count : 0
        acc.push({
          label: grade.nameOrQStripped,
          count: count,
          percent: !maxVote.count ? '0%' : round((count / maxVote.count) * 100) + '%',
        })
        return acc
      }, [])
    },
  },
}
</script>

<style lang="sass">
.tl-grade-votes
  width: 100%
  max-width: 450px
  height: 100px
  padding: 1.5em 0 2em
  position: relative
  &__overlay
    @include tl-center
    top: 25%
  &__item
    height: 100%
    position: relative
  &__bar
    position: relative
    background-color: var(--v-primary-base)
    margin: 0 2px
    border-radius: 4px 4px 0 0
    transition: height 300ms
  &__count
    position: absolute
    bottom: 100%
    color: var(--v-primary-base)
    transform: translateX(-50%)
    left: 50%
  &__axis
    position: absolute
    top: 100%
    border-top: 1px solid var(--v-grey-base)
    border-left: 1px solid var(--v-grey-base)
    height: 6px
    width: 100%
  &__item:last-of-type &__axis
    border-right: 1px solid var(--v-grey-base)
  &__label
    text-align: center
    position: absolute
    top: 100%
    margin-top: 6px
    transform: translateX(-50%)
    left: 50%
</style>
