<template>
  <v-layout column class="tl-climbs-list">
    <v-list two-line class="py-0">
      <tl-climbs-list-group
        v-for="group in groups"
        :key="group.id"
        :group="group"
        :show-checks="showChecks"
        :loggable="loggable"
        :editable="editable"
        :mini="mini"
        @updateGroupFiltered="updateGroupFiltered"
      />
      <div v-if="filteredGroupsMsg && !mini">
        <v-divider inset></v-divider>
        <v-subheader class="text-no-wrap">{{ filteredGroupsMsg }}</v-subheader>
      </div>
    </v-list>
    <v-list id="groupless-climbs-list" subheader class="py-0">
      <v-divider v-if="groups.length && grouplessClimbs.length" />
      <tl-climbs-list-climb
        v-for="climb in grouplessClimbsFiltered"
        :key="climb.id"
        :climb="climb"
        :show-checks="showChecks"
        :loggable="loggable"
        selectable
      />
      <!-- Leave this for a while, see if we get any comments about setters missing it... -->
      <!-- <slick-list
        v-model="grouplessClimbsFiltered"
        lock-axis="y"
        lock-offset="10%"
        use-drag-handle
        lock-to-container-edges
        append-to="#groupless-climbs-list"
        class="grey lighten-2"
        helper-class="elevation-4"
      >
        <slick-item
          v-for="(climb, index) in grouplessClimbsFiltered"
          :index="index"
          :key="climb.id"
          :disabled="!editable"
        >
          <tl-climbs-list-climb
            :climb="climb"
            :show-checks="showChecks"
            :loggable="loggable"
            :sortable="editable"
            selectable
          />
        </slick-item>
      </slick-list> -->
      <tl-filtered-msg :count="filteredGrouplessCount" />
    </v-list>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import tlClimbsListGroup from './tl-climbs-list-group'
import tlClimbsListClimb from './tl-climbs-list-climb'
import tlFilteredMsg from './tl-filtered-msg'

export default {
  components: {
    tlClimbsListGroup,
    tlClimbsListClimb,
    tlFilteredMsg,
  },
  props: {
    showChecks: { type: Boolean, default: false },
    loggable: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    mini: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    filteredGroups: {},
  }),
  computed: {
    ...mapGetters('climbs', ['groups', 'grouplessClimbs']),
    grouplessClimbsFiltered: {
      get() {
        return this.$store.getters['climbs/grouplessClimbsFiltered']
      },
      set(orderedClimbs) {
        this.saveNewOrder(orderedClimbs)
      },
    },
    filteredGroupsCount() {
      return Object.values(this.filteredGroups).filter(filtered => filtered).length
    },
    filteredGroupsMsg() {
      if (!this.filteredGroupsCount) return ''
      let msg = `climbs.group.hiddenGroup${this.filteredGroupsCount > 1 ? 's' : ''}`
      return this.$t(msg, { count: this.filteredGroupsCount })
    },
    filteredGrouplessCount() {
      return this.grouplessClimbs.length - this.grouplessClimbsFiltered.length
    },
  },
  methods: {
    updateGroupFiltered(groupId, filtered) {
      this.$set(this.filteredGroups, groupId, filtered)
    },
    saveNewOrder(orderedClimbs) {
      if (this.loading) return
      this.loading = true
      let dfds = orderedClimbs.map((climb, index) => {
        climb.$update({ order: index })
        return climb.$apiSave()
      })
      Promise.all(dfds).finally(() => (this.loading = false))
    },
  },
}
</script>
