import d3 from '@/services/d3-stripped'
import { filterClimbsInView, getZoomClimbs, getZoomWalls } from './map-utils'

const state = () => ({
  wView: 0,
  hView: 0,
  zoomOffsetLeft: 0,
  wDrawingBase: 0,
  hDrawingBase: 0,
  xDrawingBase: 0,
  yDrawingBase: 0,
  selectedWall: null,
  transform: d3.zoomIdentity,
  nextTransform: null,
  scaleBounds: [0.1, 8],
  mapWalls: [],
  climbs: [],
  zoomable: true,
})

const getters = {
  wDrawing(state) {
    return state.wDrawingBase * state.transform.k
  },
  hDrawing(state) {
    return state.hDrawingBase * state.transform.k
  },
  visibleBounds(state, getters) {
    let xMin = -state.transform.x / getters.wDrawing
    let xMax = xMin + state.wView / getters.wDrawing
    let yMin = -state.transform.y / getters.hDrawing
    let yMax = yMin + state.hView / getters.hDrawing
    return { xMin, xMax, yMin, yMax }
  },
  zoomWalls(state) {
    let walls = state.mapWalls.map(mapWall => ({ x: mapWall.wall.label_x, y: mapWall.wall.label_y }))
    return getZoomWalls({
      walls,
      wDrawing: state.wDrawingBase,
      hDrawing: state.hDrawingBase,
      xDrawing: state.xDrawingBase,
      yDrawing: state.yDrawingBase,
    })
  },
  zoomClimbs(state, getters, rootState, rootGetters) {
    let zoomClimbs = getZoomClimbs({
      wRel: state.wView / state.wDrawingBase,
      hRel: state.hView / state.hDrawingBase,
      xRel: state.xDrawingBase / state.wDrawingBase,
      yRel: state.yDrawingBase / state.hDrawingBase,
      climbs: rootGetters['climbs/filteredClimbs'],
      maxClimbs: 40,
      zoomBounds: state.scaleBounds,
    })
    if (zoomClimbs < getters.zoomWalls) return getters.zoomWalls * 2
    return zoomClimbs
  },
}

const mutations = {
  setViewSize(state, viewBBox) {
    state.wView = viewBBox.width
    state.hView = viewBBox.height
  },
  setZoomOffsetLeft(state, offset) {
    state.zoomOffsetLeft = offset || 0
  },
  setDrawingBaseSize(state, drawingBBox) {
    state.wDrawingBase = drawingBBox.width
    state.hDrawingBase = drawingBBox.height
    state.xDrawingBase = drawingBBox.x
    state.yDrawingBase = drawingBBox.y
  },
  setSelectedWall(state, wall) {
    state.selectedWall = wall
  },
  setTransform(state, transform) {
    state.transform = transform // d3 transform objects have a custom toString function: https://github.com/d3/d3-zoom#transform_toString
  },
  setNextTransform(state, nextTransform) {
    state.nextTransform = nextTransform
  },
  setMapWalls(state, mapWalls) {
    state.mapWalls = mapWalls
  },
  updateClimbs(state, climbs) {
    state.climbs = climbs
  },
  setZoomable(state, newVal) {
    state.zoomable = newVal
  },
}

const actions = {
  zoomTo({ state, commit }, { x, y, z, zMin, zMax }) {
    if (zMin && z < zMin) z = zMin
    if (zMax && z > zMax) z = zMax
    z = Math.max(state.scaleBounds[0], Math.min(state.scaleBounds[1], z))
    let dx = state.zoomOffsetLeft
    let translate = [dx + (state.wView - dx) / 2 - z * x, state.hView / 2 - z * y]
    let nextTransform = d3.zoomIdentity.translate(translate[0], translate[1]).scale(z)
    commit('setNextTransform', nextTransform)
  },
  zoomToElem({ state, dispatch }, { elem, zMin, zMax } = {}) {
    let bbox = elem
      ? elem.getBBox()
      : {
          width: state.wDrawingBase,
          height: state.hDrawingBase,
          x: state.xDrawingBase,
          y: state.yDrawingBase,
        }
    let x = bbox.x + bbox.width / 2
    let y = bbox.y + bbox.height / 2

    // Get the greatest relative size (w or h) that fits in the svg window
    let z = 0.9 / Math.max(bbox.width / state.wView, bbox.height / state.hView)
    dispatch('zoomTo', { x, y, z, zMin, zMax })
  },
  zoomToClimb({ state, getters, dispatch }, climb) {
    let climbInView = filterClimbsInView([climb], getters.visibleBounds).length
    let climbShown = state.transform.k >= getters.zoomClimbs
    if (!climbInView || !climbShown) {
      dispatch('zoomTo', {
        x: (climb.position_x || 0) * state.wDrawingBase,
        y: (climb.position_y || 0) * state.hDrawingBase,
        z: getters.zoomClimbs * 2,
      })
      return new Promise(resolve => setTimeout(resolve, 1200))
    } else {
      return Promise.resolve()
    }
  },
  updateClimbs({ state, getters, rootGetters, commit }) {
    let climbs = rootGetters['climbs/filteredClimbs']
    let climbsToShow = []
    if (state.transform.k >= getters.zoomClimbs) {
      climbsToShow = filterClimbsInView(climbs, getters.visibleBounds)
    }
    commit('updateClimbs', climbsToShow)
  },
  kioskDemoZoom({ state, getters, dispatch }) {
    // Alternately zoom in and out to random wall to prevent screen burn-in:
    if (state.transform.k >= getters.zoomClimbs) {
      dispatch('zoomToElem')
    } else {
      let randomMapWall = state.mapWalls[Math.floor(Math.random() * state.mapWalls.length)]
      if (!randomMapWall || !randomMapWall.wallElem) return
      dispatch('zoomToElem', { elem: randomMapWall.wallElem, zMin: getters.zoomClimbs })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
