<template>
  <div :style="{ height: height }">
    <svg
      v-resize="resized"
      xmlns="http://www.w3.org/2000/svg"
      class="tl-climbs-map"
      :class="{ 'set-pos-mode': setPositionMode }"
      height="100%"
      width="100%"
      version="1.1"
      @click="outsideMapClick()"
    >
      <g ref="translation" @click="mapClick($event)">
        <g ref="drawing">
          <tl-climbs-map-drawing @drawing-loaded="reset" />
        </g>
        <svg ref="overlay">
          <tl-climbs-map-climb
            v-for="climb in climbs"
            :key="climb.id"
            :climb="climb"
            :show-checks="showChecks"
            :draggable="editable"
            @click.native="climbClick($event, climb)"
          />
          <tl-climbs-map-wall
            v-for="mapWall in mapWalls"
            :key="mapWall.wallId"
            :wall="mapWall.wall"
            :wall-id="mapWall.wallId"
            :wall-elem="mapWall.wallElem"
            :show-checks="showChecks"
            @wall-click="wallClick"
          />
        </svg>
      </g>
    </svg>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import d3 from '@/services/d3-stripped'
import climbsMapStore from './climbs-map.store'
import tlClimbsMapDrawing from './tl-climbs-map-drawing'
import tlClimbsMapClimb from './tl-climbs-map-climb'
import tlClimbsMapWall from './tl-climbs-map-wall'
import { getClickedWallId } from './map-utils'

export default {
  components: {
    tlClimbsMapDrawing,
    tlClimbsMapClimb,
    tlClimbsMapWall,
  },
  props: {
    showChecks: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    height: { type: String, default: '100%' },
  },
  computed: {
    ...mapState(['gym']),
    ...mapState('climbs', ['view', 'setPositionMode']),
    ...mapState('climbs/climbsMap', [
      'wView',
      'hView',
      'wDrawingBase',
      'hDrawingBase',
      'transform',
      'nextTransform',
      'scaleBounds',
      'selectedWall',
      'mapWalls',
      'climbs',
      'zoomable',
    ]),
    ...mapState('selection', ['selection']),
    ...mapGetters('climbs', ['filteredClimbs']),
    ...mapGetters('climbs/climbsMap', ['zoomWalls', 'zoomClimbs', 'wDrawing', 'hDrawing']),
    zoom() {
      const elTrans = d3.select(this.$refs.translation)
      const elDrawing = d3.select(this.$refs.drawing)
      const elOverlay = d3.select(this.$refs.overlay)
      return d3
        .zoom()
        .scaleExtent(this.scaleBounds)
        .on('zoom', () => {
          if (!this.zoomable) return

          let x = d3.event.transform.x || 0
          let y = d3.event.transform.y || 0
          let k = d3.event.transform.k || 1

          elTrans.attr('transform', d3.zoomIdentity.translate(x, y))
          elDrawing.attr('transform', d3.zoomIdentity.scale(k))
          // The overlay svg is not scaled, so the drawn items keep the same size:
          elOverlay.attr('width', this.wDrawingBase * k)
          elOverlay.attr('height', this.hDrawingBase * k)
        })
        .on('end', () => {
          if (!this.zoomable) return
          this.$store.commit('climbs/climbsMap/setTransform', d3.event.transform)
          this.$store.commit('climbs/climbsMap/setNextTransform', null)
          // Deselect the wall if we are zoomed out:
          if (d3.event.transform.k < this.zoomClimbs) {
            this.$store.commit('climbs/climbsMap/setSelectedWall', null)
          }
          this.$store.dispatch('climbs/climbsMap/updateClimbs')
        })
    },
    resetTriggers() {
      return this.height + this.view
    },
  },
  watch: {
    resetTriggers() {
      this.$nextTick(this.reset)
    },
    'filteredClimbs.length'() {
      this.$store.dispatch('climbs/climbsMap/updateClimbs')
    },
    nextTransform(newTransform) {
      if (newTransform) this.zoomToTransform(newTransform)
    },
  },
  beforeCreate() {
    this.$store.registerModuleOnce(['climbs', 'climbsMap'], climbsMapStore)
  },
  mounted() {
    d3.select(this.$el).call(this.zoom) // Initialize zoom functionality to svg element
    this.zoomToTransform(this.transform)
  },
  methods: {
    ...mapActions('selection', { clearSelection: 'clear' }),
    zoomToTransform(newTransform) {
      d3.select(this.$el)
        .transition()
        .duration(500)
        .call(this.zoom.transform, newTransform)
    },
    resized() {
      this.$store.commit('climbs/climbsMap/setViewSize', this.$el.getBoundingClientRect())
    },
    reset() {
      this.$store.commit('climbs/climbsMap/setViewSize', this.$el.getBoundingClientRect())
      if (this.selection.length == 1) {
        this.$store.dispatch('climbs/climbsMap/zoomToClimb', this.selection[0])
      } else {
        this.$store.dispatch('climbs/climbsMap/zoomToElem')
      }
    },
    getClickCoordinates(e) {
      return {
        x: (e.offsetX - this.transform.x) / this.wDrawing,
        y: (e.offsetY - this.transform.y) / this.hDrawing,
      }
    },
    mapClick(event) {
      event.tlMapClick = true
      if (this.setPositionMode) {
        this.$emit('map-click', {
          coords: this.getClickCoordinates(event),
          wallId: getClickedWallId({ event, gymId: this.gym.id }),
        })
        return
      }
      if (!event.tlClimbClick && this.selection.length) this.clearSelection()
    },
    outsideMapClick() {
      if (event.tlMapClick) return
      this.selection.length ? this.clearSelection() : this.$store.dispatch('climbs/climbsMap/zoomToElem')
    },
    wallClick(wallComp) {
      if (this.setPositionMode) return
      if (this.selection.length) return this.clearSelection()
      if (this.selectedWall == wallComp.wall) {
        this.$store.commit('climbs/climbsMap/setSelectedWall', null)
        this.$store.dispatch('climbs/climbsMap/updateClimbs') // With the wall no longer selected, this will probably collapse the climbs.
        this.$store.dispatch('climbs/climbsMap/zoomToElem', { elem: this.$refs.drawing })
      } else {
        this.$store.commit('climbs/climbsMap/setSelectedWall', wallComp.wall)
        this.$store.dispatch('climbs/climbsMap/zoomToElem', { elem: wallComp.wallElem, zMin: this.zoomClimbs })
      }
    },
    climbClick(event, climb) {
      event.tlClimbClick = true
      this.$store.dispatch('selection/handleClick', { event, item: climb })
    },
  },
}

// https://bl.ocks.org/sistemawebpro/41d81ad70d7355691a61a6f7ac7f83d9
</script>

<style lang="sass">
svg.tl-climbs-map
  // Prevent fixed-positioned foreignObjects from showing over any other element:
  z-index: 0
  // To appear in padding area behind list view on wide screens
  overflow: visible
  cursor: pointer
  svg,
  foreignObject
    overflow: visible
  &.set-pos-mode .tl-climbs-map-climb
    pointer-events: none
</style>
