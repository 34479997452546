<template>
  <div>
    <v-layout mx-0 pb-3 row justify-space-between align-center>
      <v-flex class="font-weight-medium grey--text text--lighten-1">Feedback</v-flex>
      <v-btn-toggle v-model="graphGradesType" mandatory>
        <v-btn text height="36" value="setters" :disabled="!gradesSetters.length">Setters</v-btn>
        <v-btn text height="36" value="all" :disabled="!gradesSetters.length">All</v-btn>
      </v-btn-toggle>
    </v-layout>
    <v-layout v-if="loading || !grades.length" column align-center justify-center style="height: 100px">
      <span v-if="loading" class="grey--text text--lighten-1 my-3">
        Loading...
      </span>
      <span v-else-if="!grades.length" class="grey--text text--lighten-1 my-3">
        No feedback yet...
      </span>
    </v-layout>
    <v-fade-transition>
      <tl-grade-votes-graph v-if="!loading && grades.length" :votes="grades" />
    </v-fade-transition>
  </div>
</template>

<script>
import tlGradeVotesGraph from './tl-grade-votes-graph'

export default {
  components: {
    tlGradeVotesGraph,
  },
  props: {
    gradesCommunity: { type: Array, default: () => [] },
    gradesSetters: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    graphGradesType: 'all',
  }),
  computed: {
    grades() {
      return this.graphGradesType == 'setters' ? this.gradesSetters : this.gradesCommunity || []
    },
  },
}
</script>
