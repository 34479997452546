import Wall from '@/models/Wall'

// Filters the passed climbs to the ones that are withing the passed view-bounds.
export function filterClimbsInView(climbs, view) {
  return climbs.filter(climb => {
    return (
      climb.position_x > view.xMin &&
      climb.position_x < view.xMax &&
      climb.position_y > view.yMin &&
      climb.position_y < view.yMax
    )
  })
}

// Given a relative view-size and relative offset, finds the maximum zoomLevel where there are never more than `maxClimbs` climbs in the view.
export function getZoomClimbs({ wRel, hRel, xRel, yRel, climbs, maxClimbs, zoomBounds }) {
  // console.time('updateZoomClimbs')
  let points = climbs.map(climb => ({ x: climb.position_x, y: climb.position_y })) // Create (fast) point objects from the climbs
  let prevPos = null

  // Checks if there are too many climbs in the w x h rectangle with upper left corner at point (x, y)
  function tooManyClimbsPos(w, h, x, y) {
    let count = points.filter(p => p.x > x && p.x < x + w && p.y > y && p.y < y + h).length
    return count > maxClimbs
  }

  // Iterate through a normalized plane from upper left (0.0) up to 1.0 minus the relative view-size (w x h).
  function tooManyClimbs(w, h) {
    // First try the previous hit
    if (prevPos && tooManyClimbsPos(w, h, prevPos.x, prevPos.y)) return true
    // If the view encapsulates the drawing (relative dimension > 1), set max to 0 to run once:
    let xMax = w >= 1 ? 0 : 1 - w
    let yMax = h >= 1 ? 0 : 1 - h
    // Steps of 0.05, so to run at most 20 times given a very small view.
    for (let x = xRel; x <= xMax + xRel; x += 0.05) {
      for (let y = yRel; y <= yMax + yRel; y += 0.05) {
        if (tooManyClimbsPos(w, h, x, y)) {
          prevPos = { x, y }
          return true
        }
      }
    }
    return false
  }

  // Binary search algorithm:
  let zMin = zoomBounds[0]
  let zMax = zoomBounds[1]
  let z = (zMin + zMax) / 2 // Initial try half way
  for (let i = 0; i < 6; i++) {
    let tooMany = tooManyClimbs(wRel / z, hRel / z)
    tooMany ? (zMin = z) : (zMax = z) // Too many climbs: zoom in by increasing the minimum. Too few: zoom out by lowering the max.
    z = (zMin + zMax) / 2
  }
  // console.timeEnd('updateZoomClimbs')
  return z
}

// Finds the smallest distance between wall-centers and determines the zoom at which the walls should collapse.
export function getZoomWalls({ walls, wDrawing, hDrawing, xDrawing, yDrawing }) {
  // First calculate the absolute point positions, as we are interested in distance.
  let points = walls.map(wall => ({
    x: wall.x * wDrawing - xDrawing,
    y: wall.y * hDrawing - yDrawing,
  }))
  let minDist = null
  points.forEach((wall1, index) => {
    points.slice(index + 1).forEach(wall2 => {
      let dist = Math.hypot(wall2.x - wall1.x, wall2.y - wall1.y)
      if (!minDist || dist < minDist) minDist = dist
    })
  })
  return 50 / minDist // 50 is an empirical value. Inversion because greater distance means less zoom.
}

export function getClickedWallId({ event, gymId }) {
  let elements = document.elementsFromPoint(event.x || event.clientX, event.y || event.clientY)
  let el = elements.find(el => el.closest('.map-region'))
  if (!el) return null

  let wallId = el.closest('.map-region').id.split('-').pop() // eslint-disable-line
  const wall = Wall.findAllBy('floorplan_id', wallId).find(wall => wall.gym_id == gymId)
  if (!wall) return null

  return wall.id
}
