import Comp from '@/models/Comp'
import { parseDateSafe } from '@/services/parsers'

const state = () => ({
  compId: null,
})

const getters = {
  climbFilter(state, gttrs, rootState) {
    return climb =>
      climb &&
      !climb.deleted &&
      climb.climb_type == rootState.climbType &&
      (rootState.isAdmin || climb.live) &&
      (!state.compId || climb.comp_climbs.some(cg => cg.group_id == state.compId))
  },
  ascendFilter(state) {
    return ascend => {
      if (!state.compId) return true
      let comp = Comp.find(state.compId)
      return (
        comp &&
        parseDateSafe(ascend.date_logged) > parseDateSafe(comp.date_loggable_start) &&
        parseDateSafe(ascend.date_logged) < parseDateSafe(comp.date_loggable_end)
      )
    }
  },
}

const mutations = {
  setCompId(state, compId) {
    state.compId = compId
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
