<template>
  <v-layout row wrap>
    <div
      v-for="hold in holds"
      :key="hold.id"
      class="selectable-color"
      :class="{ selected: selected(hold.id) }"
      @click="toggleSelect(hold.id)"
    >
      <tl-grade-circle :color="hold.color" :color-secondary="hold.color_secondary" />
    </div>
  </v-layout>
</template>

<script>
import tlGradeCircle from '../tl-grade-circle'

export default {
  components: {
    tlGradeCircle,
  },
  props: {
    multiple: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    value: { type: [Array, Number], default: () => [] },
    holds: { type: Array, default: () => [] },
  },
  computed: {
    selection: {
      get: function() {
        const valArr = Array.isArray(this.value) ? this.value : [this.value]
        return new Set(valArr)
      },
      set: function(ids) {
        let idsArr = [...ids]
        let selection = this.multiple ? idsArr.sort() : (idsArr.length && idsArr[0]) || null
        this.$emit('input', selection)
      },
    },
  },
  methods: {
    selected(holdId) {
      return this.selection.has(holdId)
    },
    toggleSelect(holdId) {
      if (this.selection.has(holdId)) {
        if (this.required && this.selection.size == 1) return
        this.multiple ? this.selection.delete(holdId) : this.selection.clear()
      } else {
        if (!this.multiple) this.selection.clear()
        this.selection.add(holdId)
      }
      this.selection = this.selection // Triggers the setter...
    },
  },
}
</script>

<style lang="sass">
.selectable-color
  padding: 8px
  transition: all 150ms
  .tl-grade-circle
    width: 30px
    height: 30px
    cursor: pointer
    transition: width 150ms, height 150ms
    box-shadow: 0 0 10px var(--v-grey-darken2)
    &:hover
      box-shadow: 0 0 10px var(--v-grey-base)
  &.selected
    padding: 2px
    .tl-grade-circle
      width: 42px
      height: 42px
      &,
      .tl-grade-circle
        border-radius: 25%
</style>
