const state = {
  propCache: {},
  propsToCache: [
    'hold_id',
    'group_id',
    'setter_id',
    'number',
    'rope_number',
    'auto_grade',
    'grade',
    'grade_stability_admin',
    'suitable_for_kids',
    'date_set',
    'date_removed',
  ],
}

const mutations = {
  setCacheProp(state, { prop, val }) {
    if (!state.propsToCache.includes(prop)) return
    if (prop === 'number' && !isNaN(parseInt(val))) {
      state.propCache[prop] = String(parseInt(val) + 1) // Auto-increase the climb number
    } else {
      state.propCache[prop] = val
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
