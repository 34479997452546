<template>
  <g id="map-drawing" />
</template>

<script>
import axios from 'axios'
import Wall from '@/models/Wall'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['gym', 'isAdmin']),
  },
  async mounted() {
    // Download the floorplan:
    let res = await axios.get(`${this.gym.srcFloorplan}`)
    if (res == undefined) return

    // Parse the map into a temporal container to be able to extract it's zoom_layer's innerHTML:
    let tempContainer = document.createElement('div')
    tempContainer.innerHTML = res.data
    let mapDrawing = tempContainer.querySelector('#zoom_layer')
    // Assign the drawing and save it's dimensions in the store:
    this.$el.innerHTML = mapDrawing.innerHTML
    this.$store.commit('climbs/climbsMap/setDrawingBaseSize', this.$el.getBBox())

    // Parse the walls with their ids and register them on the store:
    let mapRegionsElems = [...this.$el.querySelectorAll('.map-region')] // Get nodelist and convert it to an array
    let mapWalls = mapRegionsElems.map(wallElem => {
      let wallId = wallElem.id.split('-').pop()
      let wall = this.getWall(wallId)
      if (!wall.label_x || !wall.label_y) {
        let centerElem = wallElem.querySelector('.map-wall-center')
        let bbox = (centerElem && centerElem.getBBox()) || wallElem.getBBox()
        let label_x = (bbox.x + bbox.width / 2) / this.$el.getBBox().width
        let label_y = (bbox.y + bbox.height / 2) / this.$el.getBBox().height
        wall.$update({ label_x, label_y })
        if (this.isAdmin) wall.$apiSave()
      }
      return {
        wallElem,
        wallId,
        wall,
      }
    })
    this.$store.commit('climbs/climbsMap/setMapWalls', mapWalls)

    // Notify the map so it can reset the view:
    this.$emit('drawing-loaded')
  },
  destroyed() {
    this.$store.commit('climbs/climbsMap/setMapWalls', [])
  },
  methods: {
    getWall(wallId) {
      const wall = Wall.findAllBy('floorplan_id', wallId).find(wall => wall.gym_id == this.gym.id)
      // eslint-disable-next-line no-console
      if (!wall) console.error(`Could not find wall with id '${wallId}'`)
      return wall
    },
  },
}
</script>

<style lang="sass">
.map-wall
  transition: fill .3s
</style>
