<template>
  <div v-if="count">
    <v-divider inset />
    <v-subheader inset>{{ filteredClimbsText }}</v-subheader>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { capitalize } from '@/services/utils'

export default {
  props: {
    count: { type: Number, default: 0 },
  },
  computed: {
    ...mapState(['climbType']),
    filteredClimbsText() {
      if (!this.count) return ''
      let key = `climbs.group.hidden${capitalize(this.climbType)}${this.count > 1 ? 's' : ''}`
      return this.$t(key, { count: this.count })
    },
  },
}
</script>
