<template>
  <v-list-group v-if="!groupHidden" v-model="expanded" :disabled="sorting || mini" class="tl-group">
    <template v-slot:activator>
      <v-list-item-action class="tl-group__grade-circle mr-4">
        <tl-climb-circle
          v-if="group.hold"
          :color="group.hold.color"
          :color-secondary="group.hold.color_secondary"
          :checks="showChecks ? aggregateProps.checks : 0"
          :selected="allSelected"
        />
        <v-icon v-else :style="{ color: group.color }" large>tl-label</v-icon>
      </v-list-item-action>
      <v-list-item-content v-if="!expanded" class="tl-group__fadeable">
        <v-list-item-title class="tl-group__title">{{ group.name }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ group.description }}
          <tl-new-label v-if="aggregateProps.anyNew" />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content v-if="expanded" class="tl-group__fadeable">
        <div class="d-flex">
          <div class="d-flex flex-grow-1">
            <v-btn block text rounded outlined @click.stop="toggleSelectAll">{{ selectallBtnText }}</v-btn>
          </div>
          <v-menu v-if="editable" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text icon outlined class="ml-2" :loading="sorting" :disabled="sorting" v-on="on" @click.stop="">
                <v-icon>tl-more-vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="item in orderables" :key="item.key" @click="orderby(item.key)">
                <v-list-item-title>Order by {{ item.name }}</v-list-item-title>
              </v-list-item>
              <div v-if="gym.edit_climb_show_number">
                <v-divider />
                <v-list-item @click="numberAsOrdered">
                  <v-list-item-title>Number as ordered</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </div>
      </v-list-item-content>
      <v-list-item-action v-if="!expanded" class="tl-group__fadeable">
        <v-list-item-action-text>
          <tl-date-removed :date="aggregateProps.closestDateRemoved" />
        </v-list-item-action-text>
      </v-list-item-action>
    </template>

    <v-list :id="`climb-list-${group.id}`" subheader>
      <slick-list
        v-model="filteredClimbs"
        lock-axis="y"
        lock-offset="10%"
        use-drag-handle
        lock-to-container-edges
        :append-to="`#climb-list-${group.id}`"
        class="grey lighten-2"
        helper-class="elevation-4"
      >
        <slick-item
          v-for="(climb, index) in filteredClimbs"
          :index="index"
          :key="climb.id"
          :disabled="!editable || sorting"
        >
          <tl-climbs-list-climb
            :climb="climb"
            :show-checks="showChecks"
            :loggable="loggable"
            :sortable="editable"
            :sorting="sorting"
            selectable
          />
        </slick-item>
      </slick-list>
    </v-list>

    <tl-filtered-msg :count="filteredClimbsCount" />
  </v-list-group>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import tlClimbsListClimb from './tl-climbs-list-climb'
import tlClimbCircle from '../tl-climb-circle'
import tlDateRemoved from './tl-date-removed'
import tlNewLabel from './tl-new-label'
import tlFilteredMsg from './tl-filtered-msg'
import { SlickList, SlickItem } from 'vue-slicksort'

export default {
  components: {
    tlClimbsListClimb,
    tlClimbCircle,
    tlDateRemoved,
    tlNewLabel,
    tlFilteredMsg,
    SlickList,
    SlickItem,
  },
  props: {
    group: { type: Object, default: () => {} },
    showChecks: { type: Boolean, default: false },
    loggable: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    mini: { type: Boolean, default: false },
  },
  data: () => ({
    expanded: false,
    sorting: false,
  }),
  computed: {
    ...mapState(['gym']),
    ...mapGetters('selection', ['selected']),
    ...mapGetters('climbs/viewFilters', { viewFilter: 'climbFilter' }),
    ...mapGetters('climbs/userFilters', { userFilter: 'climbFilter' }),
    orderables() {
      let arr = [{ name: 'grade', key: 'grade' }]
      if (this.gym.edit_climb_show_number) arr.push({ name: 'number', key: 'number' })
      if (this.gym.rope_numbers) arr.push({ name: 'rope number', key: 'rope_number' })
      return arr
    },
    climbs() {
      return this.group.climb_groups
        .slice()
        .sort((a, b) => a.order - b.order)
        .map(cg => cg.climb)
        .filter(this.viewFilter)
    },
    filteredClimbs: {
      get() {
        return this.climbs.filter(this.userFilter)
      },
      set(orderedClimbs) {
        this.saveNewOrder(orderedClimbs)
      },
    },
    groupHidden() {
      return !this.filteredClimbs.length
    },
    groupFiltered() {
      return this.climbs.length > 0 && this.groupHidden
    },
    filteredClimbsCount() {
      return this.climbs.length - this.filteredClimbs.length
    },
    aggregateProps() {
      let props = {
        anyNew: false,
        commonDateRemoved: undefined,
        closestDateRemoved: undefined,
        checks: 0,
      }
      if (!this.climbs.length) return props

      props = {
        anyNew: !!this.climbs[0].isNew,
        commonDateRemoved: this.climbs[0].date_removed,
        closestDateRemoved: this.climbs[0].date_removed,
        checks: this.climbs[0].checks,
      }
      this.climbs.forEach(climb => {
        if (climb.isNew) props.anyNew = true
        if (props.commonDateRemoved && !this.moment(climb.date_removed).isSame(props.commonDateRemoved, 'day'))
          props.commonDateRemoved = null
        if (
          climb.date_removed &&
          props.closestDateRemoved &&
          this.moment(climb.date_removed).isBefore(this.moment(props.closestDateRemoved))
        )
          props.closestDateRemoved = climb.date_removed
        if (climb.checks < props.checks) props.checks = climb.checks
      })
      return props
    },
    allSelected() {
      return this.filteredClimbs.every(this.selected)
    },
    selectallBtnText() {
      return this.$t(this.allSelected ? 'climbs.filters.deselectAll' : 'climbs.filters.selectAll')
    },
  },
  watch: {
    mini() {
      this.expanded = false
    },
    groupFiltered: {
      immediate: true,
      handler(newVal) {
        this.$emit('updateGroupFiltered', this.group.id, newVal)
      },
    },
  },
  methods: {
    ...mapMutations('selection', ['setMultiSelectMode']),
    ...mapActions('selection', ['select', 'deselect']),
    toggleSelectAll() {
      this.setMultiSelectMode(true)
      let method = this.allSelected ? this.deselect : this.select
      this.filteredClimbs.forEach(climb => {
        method(climb)
      })
    },
    orderby(key) {
      let orderedClimbs = this.climbs.slice().sort((a, b) => a[key] - b[key])
      this.saveNewOrder(orderedClimbs)
    },
    saveNewOrder(orderedClimbs) {
      if (this.sorting) return
      let orderedClimbGroups = orderedClimbs.map(climb => climb.climb_groups.find(cg => cg.group_id == this.group.id))
      this.sorting = true
      let dfds = orderedClimbGroups.map((cg, index) => {
        cg.$update({ order: index })
        return cg.$apiSave()
      })
      Promise.all(dfds).finally(() => (this.sorting = false))
    },
    numberAsOrdered() {
      this.sorting = true
      let dfds = this.climbs.map((climb, index) => {
        climb.$update({ number: index + 1 })
        return climb.$apiSave()
      })
      Promise.all(dfds).finally(() => (this.sorting = false))
    },
  },
}
</script>

<style lang="sass">
.tl-group
  &--fade .tl-group__fadeable
    opacity: 0.4
  &__grade-circle
    min-width: 40px
    height: 40px
  &__title
    font-size: 20px
</style>
