<template>
  <div class="tl-grade-slider" @touchstart.stop="">
    <v-slider
      :value="index"
      :max="iMax"
      :readonly="disabled"
      :color="disabled ? 'grey darken-2' : 'primary'"
      thumb-label="always"
      thumb-size="40"
      always-dirty
      hide-details
      class="pt-10"
      @change="index = $event"
    >
      <template v-slot:prepend>
        <v-btn icon :disabled="minDisabled" class="ma-0" :small="smallIcons">
          <v-icon @click="decrement">tl-chevron-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:append>
        <v-btn icon :disabled="maxDisabled" class="ma-0" :small="smallIcons">
          <v-icon @click="increment">tl-chevron-right</v-icon>
        </v-btn>
      </template>
      <template v-slot:thumb-label="props">
        <span class="body-1" v-html="gradeName(props.value)" />
      </template>
    </v-slider>
  </div>
</template>

<script>
import tlGradeSliderMixin from './tl-grade-slider.mixin'

export default {
  mixins: [tlGradeSliderMixin],
  props: {
    value: { type: Number, default: 0 },
    smallIcons: { type: Boolean, default: false },
  },
  computed: {
    index: {
      get() {
        return this.valueToIndex(this.value)
      },
      set(newIndex) {
        this.$emit('input', this.indexToValue(newIndex))
      },
    },
    minDisabled() {
      return this.index <= 0 || this.disabled
    },
    maxDisabled() {
      return this.index >= this.iMax || this.disabled
    },
  },
  methods: {
    decrement() {
      if (this.minDisabled) return
      this.index = this.index - 1
    },
    increment() {
      if (this.maxDisabled) return
      this.index = this.index + 1
    },
  },
}
</script>

<style lang="sass">
.tl-grade-slider
  .v-input__append-outer,
  .v-input__prepend-outer
    margin-top: -2px
  .v-slider__thumb,
  .v-slider__thumb-label__container
    cursor: pointer
</style>
