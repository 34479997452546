<template>
  <div class="tl-swipe-sheet elevation-8" :class="classes" :style="style" ref="container">
    <div v-show="!inoperative" class="tl-swipe-sheet__handle" ref="handle" />
    <div class="tl-swipe-sheet__scroll-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs'

export default {
  props: {
    state: { type: String, default: 'closed' },
    topHalf: { type: Number, default: 50 },
    inoperative: { type: Boolean, default: false },
  },
  data: () => ({
    mc: null,
    swiping: false,
    topSwipe: 0,
    topSwipeStart: 0,
    threshold: 50,
  }),
  computed: {
    classes() {
      return [this.swiping ? 'swiping' : this.state, { inoperative: this.inoperative }]
    },
    style() {
      if (this.inoperative) return ''
      return { top: this.swiping ? this.topSwipe + 'px' : this.topState + '%' }
    },
    topState() {
      if (this.state == 'open') return 0
      if (this.state == 'half') return this.topHalf
      return 110
    },
  },
  mounted() {
    this.addGesture()
  },
  beforeDestroy() {
    this.mc.destroy()
  },
  methods: {
    addGesture() {
      this.mc = new Hammer(this.$refs.handle, {
        recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_VERTICAL }]],
      })
      this.mc.on('panstart', () => {
        this.topSwipeStart = this.$refs.container.offsetTop
        this.swiping = true
      })
      this.mc.on('panup pandown', e => {
        this.topSwipe = Math.max(0, this.topSwipeStart + e.deltaY)
      })
      this.mc.on('panend pancancel', e => {
        this.swiping = false
        if (this.state == 'half') {
          if (e.deltaY > this.threshold) return this.setState('closed')
          if (e.deltaY < -this.threshold) return this.setState('open')
        } else if (this.state == 'open') {
          if (e.deltaY > this.threshold * 2) return this.setState('closed')
          if (e.deltaY > this.threshold) return this.setState('half')
        }
      })
    },
    setState(newState) {
      this.$emit('update:state', newState)
    },
  },
}
</script>

<style lang="sass" scoped>
$handle-height: 30px

.tl-swipe-sheet
  background-color: white
  position: absolute
  z-index: 2
  border-radius: 10px 10px 0 0
  transition: top .3s ease-out
  overflow-y: scroll
  -webkit-overflow-scrolling: touch
  &:not(.inoperative)
    padding-bottom: $handle-height
    width: 100%
    top: 100%
    bottom: 0
    box-shadow: 0 -3px 4px rgba(0, 0, 0, .1) !important
    overflow: hidden
    &.open
      border-radius: 0
    .tl-swipe-sheet__scroll-wrapper
      height: 100%
      overflow-y: scroll
      -webkit-overflow-scrolling: touch
  &.swiping
    transition: none
  &__handle
    height: $handle-height
    cursor: pointer
    position: relative
    border-bottom: 1px solid var(--v-grey-lighten1)
    &::after
      @include tl-center
      content: ''
      height: 8px
      width: 44px
      border-radius: 4px
      background-color: var(--v-grey-lighten1)
</style>
