<template>
  <v-data-table
    :loading="loading"
    :headers="enabledHeaders"
    :items="filteredClimbsSorted"
    :footer-props="{ itemsPerPageOptions: [20, 50, 100, -1] }"
    :items-per-page="20"
    :mobile-breakpoint="0"
    height="100%"
    fixed-header
    calculate-widths
    show-select
    :value="selectionArr"
    @input="setSelection"
    @click:row="toggleSelect"
    class="tl-climbs-table"
  >
    <template v-slot:header.data-table-select="{ on, props }">
      <v-progress-circular v-if="selectingArr.length" color="primary" size="20" width="2" indeterminate />
      <v-simple-checkbox v-else color="primary" v-bind="props" v-on="on" />
    </template>

    <template v-slot:item.data-table-select="{ item, isSelected, select }">
      <v-progress-circular v-if="selecting(item)" color="primary" size="20" width="2" indeterminate />
      <v-simple-checkbox v-else color="primary" :value="isSelected" @input="select($event)" />
    </template>

    <template v-slot:item.checks="{ value }">
      <v-icon :color="value ? 'success' : 'error'">{{ value ? 'tl-check-circle' : 'tl-close-circle' }}</v-icon>
    </template>
    <template v-slot:item.live="{ value }">
      <v-icon :color="!value ? 'error' : ''">{{ value ? 'tl-check' : 'tl-close' }}</v-icon>
    </template>
    <template v-slot:item.hold.brand="{ item }">
      <v-layout>
        <tl-grade-circle :color="item.hold.color" :color-secondary="item.hold.colorSecondary" size="20" />
        <span class="ml-2">{{ item.hold.brand }}</span>
      </v-layout>
    </template>
    <template v-slot:item.grade="{ value }">
      <span v-html="getGradeName(value)" />
    </template>
    <template v-slot:item.grade_stability="{ value }">
      <v-layout>
        <tl-grade-circle color="white" :grade-stability="value" size="20" />
        <span class="ml-2">{{ value }}</span>
      </v-layout>
    </template>
    <template v-slot:item.auto_grade="{ value }">
      <v-icon>{{ value ? 'tl-check' : 'tl-close' }}</v-icon>
    </template>
    <template v-slot:item.average_opinion="{ value }"><v-icon>tl-star</v-icon>{{ value }}</template>
    <template v-slot:item.suitable_for_kids="{ value }">
      <v-icon>{{ value ? 'tl-check' : 'tl-close' }}</v-icon>
    </template>
    <template v-slot:item.renew="{ value }">
      <v-icon>{{ value ? 'tl-check' : 'tl-close' }}</v-icon>
    </template>
    <template v-slot:item.date_set="{ value }">{{ moment(value).format('ll') }}</template>
    <template v-slot:item.date_removed="{ value }">{{ moment(value).format('ll') }}</template>
    <template v-slot:item.created_at="{ value }">{{ moment(value).format('ll') }}</template>
  </v-data-table>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import tlGradeCircle from '../tl-grade-circle'
import Grade from '@/services/grades/Grade'
import { Ripple } from 'vuetify/lib/directives'

export default {
  directives: {
    Ripple,
  },
  components: {
    tlGradeCircle,
  },
  computed: {
    ...mapState(['gym']),
    ...mapState('climbs', ['loading']),
    ...mapState('selection', { selectionArr: 'selection', selectingArr: 'selecting' }),
    ...mapGetters('selection', ['selecting']),
    ...mapGetters('climbs', ['filteredClimbs']),
    filteredClimbsSorted() {
      return this.filteredClimbs.slice().sort((a, b) => a.grade - b.grade || a.order - b.order)
    },
    headers() {
      return [
        { text: 'Added to comp', value: 'checks', width: 150, enabled: this.$route.name == 'admin.comp.climbs' },
        { text: 'Live', value: 'live', width: 90 },
        { text: '#', value: 'number', width: 60, enabled: this.gym.edit_climb_show_number },
        { text: 'Rope', value: 'rope_number', width: 90, enabled: this.gym.rope_numbers },
        { text: 'Color', value: 'hold.brand', width: 110 },
        { text: 'Grade', value: 'grade', width: 90 },
        // { text: 'Opinion', value: 'community_grades' },
        { text: 'Autograde', value: 'auto_grade', width: 110 },
        { text: 'Stability', value: 'grade_stability', width: 110 },
        { text: 'Name', value: 'name', width: 130, isText: true, enabled: this.gym.edit_climb_show_name },
        { text: 'Wall', value: 'wall.name', isText: true },
        { text: 'Setter', value: 'setter.name', width: 130, isText: true, enabled: this.gym.edit_climb_show_setter },
        { text: 'Logs', value: 'nr_of_ascends', width: 80 },
        { text: 'Rating', value: 'average_opinion', width: 90 },
        // { text: 'Votes for renewal', value: 'nr_of_votes_for_renew', enabled: this.gym.vote_renewal },
        { text: 'Zones', value: 'zones', enabled: this.gym.show_zones, width: 90 },
        { text: 'Remarks', value: 'remarks', isText: true, width: 130, enabled: this.gym.edit_climb_show_remarks },
        { text: 'For kids', value: 'suitable_for_kids', enabled: this.gym.show_for_kids, width: 120 },
        // { text: 'Competitions', value: 'comps[0].name', width: 130 },
        // template: '<span class="group-name" ng-repeat="group in climb.getCompetitions()">{{group.name}}</span>'
        { text: this.climbType == 'route' ? 'Groups' : 'Circuits', value: 'climbGroupNames', width: 130 },
        { text: 'Renew', value: 'renew', enabled: this.gym.renew, width: 110 },
        { text: 'Set', value: 'date_set', width: 130 },
        { text: 'Removed', value: 'date_removed', width: 130 },
        { text: 'Created', value: 'created_at', width: 130 },
      ]
    },
    enabledHeaders() {
      return this.headers.filter(header => header.enabled != false)
    },
  },
  created() {
    if (!this.$store.state.isAdmin) this.$store.commit('climbs/setView', 'map')
  },
  methods: {
    ...mapActions('selection', ['setSelection', 'toggleSelect']),
    getGradeName(grade) {
      return new Grade(grade).nameOrQ
    },
  },
}
</script>

<style lang="sass">
.tl-climbs-table
  height: 100%
  padding-bottom: 60px
</style>
