import Grade from '@/services/grades/Grade'

export default {
  props: {
    type: { type: String, default: 'boulder' },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    iMax() {
      return Grade.system(this.type).data.length - 1
    },
  },
  methods: {
    valueToIndex(value) {
      return new Grade(value, this.type).idxRound
    },
    indexToValue(index) {
      return Grade.system(this.type).data[index].value
    },
    gradeName(index) {
      return new Grade(this.indexToValue(index), this.type).nameOrQStripped
    },
  },
}
