<template>
  <div class="tl-grade-circle" :style="circleStyles">
    <span v-if="colorSecondary" class="tl-grade-circle__secondary" :style="{ 'background-color': colorSecondary }" />
    <span v-if="gradeName" class="tl-grade-circle__grade" v-html="gradeName" />
    <span v-if="gradeStability < 1.0" class="tl-grade-circle__stability" :class="gradeStabilityClass" />
  </div>
</template>

<script>
import Grade from '@/services/grades/Grade'
import { contrastingColor, hexColorDelta } from '@/services/utils'

export default {
  props: {
    grade: { type: Number, default: null },
    gradeStability: { type: Number, default: 1 },
    color: { type: String, default: '#808080' },
    colorSecondary: { type: String, default: null },
    size: { type: String, default: null },
    outlined: { type: Boolean, default: false },
  },
  computed: {
    gradeName() {
      if (this.grade == null) return ''
      return new Grade(this.grade).nameOrQStripped
    },
    textColor() {
      if (this.outlined) return 'black'
      if (this.color == '#808080') return '#fff'
      return contrastingColor(this.color)
    },
    textShadow() {
      if (!this.colorSecondary) return 'none'
      let contrastColorSecondary = contrastingColor(this.colorSecondary)
      if (this.textColor == contrastColorSecondary) return 'none'
      return `0 0 3px ${contrastColorSecondary}`
    },
    circleStyles() {
      return {
        color: this.textColor,
        width: this.size ? this.size + 'px' : null,
        height: this.size ? this.size + 'px' : null,
        'background-color': this.outlined ? 'white' : this.color,
        'text-shadow': this.textShadow,
        border: this.outlined ? `${this.size ? this.size * 0.07 : 3}px solid ${this.color}` : 'none',
      }
    },
    gradeStabilityClass() {
      let half = this.gradeStability >= 0.5
      let hardToSee = hexColorDelta(this.colorSecondary || this.color, half ? 'ffcf00' : 'ff0000') < 0.4
      return {
        'tl-grade-circle__stability--half': half,
        'tl-grade-circle__stability--contrasting': hardToSee,
      }
    },
  },
}
</script>

<style lang="sass">
.tl-grade-circle
  font-size: 1.15rem !important
  font-weight: 500
  position: relative
  width: 100%
  height: 100%
  border-radius: 50%
  box-shadow: 0 0 10px black
  &__secondary
    position: absolute
    width: 50%
    height: 100%
    top: 0
    left: 50%
    border-radius: 0 1000px 1000px 0
    transform-origin: 0 50%
    transform: rotate(45deg)
    background-color: gray
  &__grade
    white-space: nowrap
    @include tl-center
  &__stability
    @include tl-center
    @include tl-square(4px, 50%)
    top: 80%
    background-color: #ff0000
    &--half
      background-color: #ffcf00
    &--contrasting
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, .4)
</style>
