<template>
  <router-view />
</template>

<script>
import store from '@/store'

export default {
  head: {
    title: 'Admin | TopLogger',
    meta: [
      { vmid: 'description', name: 'description', content: 'Admin panel for TopLogger' },
      { name: 'robots', content: 'noindex, follow' },
    ],
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('setIsAdmin', true)
    next()
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('setIsAdmin', false)
    next()
  },
}
</script>
