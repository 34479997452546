<template>
  <div class="tl-climb-circle" :class="{ 'tl-climb-circle--completed': checks }">
    <div v-if="number" class="tl-climb-circle__number">{{ number }}</div>
    <tl-grade-circle
      :color="color"
      :color-secondary="colorSecondary"
      :grade="grade"
      :grade-stability="gradeStability"
    />
    <v-fade-transition>
      <v-icon v-if="selected" class="tl-climb-circle__selected-icon">tl-check</v-icon>
    </v-fade-transition>
    <tl-checks-circle class="tl-climb-circle__checks" :checks="checks" />
    <v-progress-circular v-if="loading" indeterminate color="white" class="tl-climb-circle__spinner" />
  </div>
</template>

<script>
import tlChecksCircle from './tl-checks-circle'
import tlGradeCircle from './tl-grade-circle'

export default {
  components: {
    tlGradeCircle,
    tlChecksCircle,
  },
  props: {
    color: { type: String, default: '#808080' },
    colorSecondary: { type: String, default: null },
    grade: { type: Number, default: null },
    gradeStability: { type: Number, default: 1 },
    checks: { type: Number, default: 0 },
    number: { type: String, default: undefined },
    selected: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
}
</script>

<style lang="sass">
.tl-climb-circle
  position: relative
  width: 40px
  height: 40px
  &__selected-icon
    @include tl-center
    width: 100%
    height: 100%
    border-radius: 100%
    color: white !important
    border: 2px solid white
    background-color: rgba(0, 0, 0, .65)
    box-shadow: 0px 0px 10px white
  &__checks
    position: absolute
    left: 0
    top: 0
    transform: translate(-40%, -40%)
  &__number
    position: absolute
    left: 50%
    top: -16px
    transform: translateX(-50%)
    height: 16px
    padding: 0 3px 25px
    border-radius: 6px
    background-color: var(--v-grey-darken4)
    color: var(--v-grey-lighten4)
    text-align: center
    font-size: 12px
    white-space: nowrap
  &__spinner
    width: 50px
    height: 50px
    @include tl-center
</style>
