<template>
  <foreignObject :x="position.x" :y="position.y" width="1" height="1" :style="{ transformOrigin: transformOrigin }">
    <div class="wall-label" :class="{ 'wall-label--small': labelSmall }">
      <span class="wall-label__text">{{ wall.name }}</span>
      <div
        v-if="climbs.length"
        class="wall-label__stats-circle stats-circle"
        :class="{ 'stats-circle--two-digits': showChecks && climbs.length > 9 }"
      >
        <template v-if="showChecks">
          <tl-checks-circle class="stats-circle__checks" :checks="checks" />
          <div class="stats-circle__left">{{ nTopped }}</div>
          <div class="stats-circle__middle title">/</div>
          <div class="stats-circle__right">{{ climbs.length }}</div>
        </template>
        <template v-else>
          <div class="stats-circle__middle">{{ climbs.length }}</div>
        </template>
      </div>
    </div>
  </foreignObject>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import d3 from '@/services/d3-stripped'
import Climb from '@/models/Climb'
import tlChecksCircle from '../tl-checks-circle'

export default {
  components: {
    tlChecksCircle,
  },
  props: {
    wall: { type: Object, default: () => {} },
    wallId: { type: String, default: '' },
    wallElem: { type: window.SVGGElement, default: () => {} },
    showChecks: { type: Boolean, default: false },
  },
  data() {
    return {
      wallParts: [],
      wallBrightness: 0,
    }
  },
  computed: {
    ...mapState(['gym']),
    ...mapState('climbs/climbsMap', ['transform']),
    ...mapGetters('climbs', ['climbFilter']),
    ...mapGetters('climbs/climbsMap', ['zoomWalls']),
    position() {
      return {
        x: (this.wall.label_x || 0) * 100 + '%',
        y: (this.wall.label_y || 0) * 100 + '%',
      }
    },
    transformOrigin() {
      return `${this.position.x} ${this.position.y}`
    },
    labelSmall() {
      return this.transform.k < this.zoomWalls
    },
    climbs() {
      return this.wall.climbs.filter(this.climbFilter)
    },
    checks() {
      return Climb.getCommonChecks(this.climbs)
    },
    nTopped() {
      return Climb.getTopped(this.climbs).length
    },
    allTopped() {
      return this.climbs.length <= this.nTopped
    },
  },
  watch: {
    wallElem() {
      this.addHandlers(this.wallElem)
    },
    wallBrightness() {
      this.updateWallBrightness()
    },
    allTopped: {
      immediate: true,
      handler(allTopped, wasAllTopped) {
        if (!this.showChecks) return
        if (allTopped) this.wallBrightness = this.wallBrightness - 2
        if (!allTopped && wasAllTopped != null) this.wallBrightness = this.wallBrightness + 2
      },
    },
  },
  created() {
    this.wallParts = [...this.wallElem.querySelectorAll('.map-wall')] // Get nodelist and convert it to an array
  },
  mounted() {
    this.addHandlers(this.wallElem)
    this.addHandlers(this.$el)
  },
  methods: {
    addHandlers(elem) {
      elem.addEventListener('click', () => this.$emit('wall-click', this))
      elem.addEventListener('mouseenter', () => (this.wallBrightness = this.wallBrightness - 1))
      elem.addEventListener('mouseleave', () => (this.wallBrightness = this.wallBrightness + 1))
    },
    updateWallBrightness() {
      let method = this.wallBrightness > 0 ? 'brighter' : 'darker'
      this.wallParts.forEach(wallPart => {
        let originalVal = wallPart.getAttribute('fill-original')
        if (!originalVal) {
          originalVal = wallPart.getAttribute('fill')
          wallPart.setAttribute('fill-original', originalVal)
        }
        let newVal = d3.rgb(originalVal)[method](Math.abs(this.wallBrightness))
        wallPart.setAttribute('fill', newVal)
      })
    },
  },
}
</script>

<style lang="sass">
$circle-size: 34px

.wall-label
  position: fixed
  opacity: 1
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)
  &--small
    transform: scale(0.5)
  &__text,
  &__stats-circle
    position: absolute
    transform: translate(-50%, -50%)
    background-color: #ff0094
    color: white
    border: 2px solid darken(#ff0094, 60%)
  &__text
    top: 24px
    border-radius: 6px
    white-space: nowrap
    padding: 1px 5px
  &__stats-circle
    width: $circle-size
    height: $circle-size
    border-radius: $circle-size
.stats-circle
  &__left,
  &__middle,
  &__right,
  &__checks
    position: absolute
    transform: translate(-50%, -50%)
  &__left
    left: 26%
    top: 40%
  &__middle
    left: 50%
    top: 50%
  &__right
    left: 74%
    top: 60%
  &--two-digits
    width: 56px
    height: 30px
    .stats-circle__topped
      left: 26%
    .stats-circle__total
      left: 72%
  &__checks
    left: -5%
    top: -5%
</style>
