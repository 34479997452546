import Grade from '@/services/grades/Grade'

const state = () => ({})

const getters = {
  filters(state, gttrs, rootState) {
    return rootState.user.filters || gttrs.filtersDefault
  },
  filtersDefault(state, gttrs, rootState) {
    let grades = Grade.system(rootState.climbType).data
    let minVal = grades[0].value
    let maxVal = grades[grades.length - 1].value

    return {
      version: 8,
      gym_id: rootState.gym.id,
      grade: [minVal, maxVal],
      stars: [0, 5],
      hold_ids: [],
      group_ids: [],
      wall_ids: [],
      project: null,
      topped: null,
      is_new: null,
      almost_removed: null,
      suitable_for_kids: null,
    }
  },
  isDefault(state, gttrs) {
    return (
      Object.keys(gttrs.filters).length == 2 &&
      gttrs.filters.version == gttrs.filtersDefault.version &&
      gttrs.filters.gym_id == gttrs.filtersDefault.gym_id
    )
  },
  gradeFilter(state, gttrs) {
    if (!gttrs.filters.grade) return () => true
    return climb => {
      return climb.grade >= gttrs.filters.grade[0] && climb.grade <= gttrs.filters.grade[1]
    }
  },
  starsFilter(state, gttrs) {
    // eslint-disable-next-line
    return climb =>
      climb.average_opinion >= gttrs.filters.stars[0] &&
      climb.average_opinion <= gttrs.filters.stars[1]
  },
  holdsFilter(state, gttrs) {
    return climb => gttrs.filters.hold_ids.includes(climb.hold_id)
  },
  groupsFilter(state, gttrs) {
    return climb => climb.climb_groups.some(climbGroup => gttrs.filters.group_ids.includes(climbGroup.group_id))
  },
  wallsFilter(state, gttrs) {
    return climb => gttrs.filters.wall_ids.includes(climb.wall_id)
  },
  projectFilter(state, gttrs) {
    return climb => gttrs.filters.project == Boolean(climb.opinion && climb.opinion.project)
  },
  toppedFilter(state, gttrs) {
    return climb => gttrs.filters.topped == Boolean(climb.checks)
  },
  climbFilter(state, gttrs) {
    let filtered = prop => gttrs.filters.hasOwnProperty(prop)
    return climb =>
      climb &&
      (!filtered('grade') || gttrs.gradeFilter(climb)) &&
      (!filtered('stars') || gttrs.starsFilter(climb)) &&
      (!filtered('hold_ids') || gttrs.holdsFilter(climb)) &&
      (!filtered('group_ids') || gttrs.groupsFilter(climb)) &&
      (!filtered('wall_ids') || gttrs.wallsFilter(climb)) &&
      (!filtered('project') || gttrs.projectFilter(climb)) &&
      (!filtered('topped') || gttrs.toppedFilter(climb)) &&
      (!filtered('is_new') || gttrs.filters.is_new == climb.isNew) &&
      (!filtered('almost_removed') || gttrs.filters.almost_removed == climb.removedSoon) &&
      (!filtered('suitable_for_kids') || gttrs.filters.suitable_for_kids == !!climb.suitable_for_kids)
  },
}

const mutations = {}

const actions = {
  updateFilters({ getters, rootState }, newFilters) {
    // Set filters to the user's filters when undefined:
    newFilters = newFilters || getters.filters || {}
    // Assure latest version and right gym:
    if (newFilters.version != getters.filtersDefault.version || newFilters.gym_id != getters.filtersDefault.gym_id) {
      newFilters = getters.filtersDefault
    }
    // Assure to only assign version, gym_id and non-default filters to te user:
    let filters = Object.keys(newFilters).reduce((acc, key) => {
      let newVal = JSON.stringify(newFilters[key])
      let defaultVal = JSON.stringify(getters.filtersDefault[key])
      if (newVal != defaultVal || key == 'version' || key == 'gym_id') {
        acc[key] = newFilters[key]
      }
      return acc
    }, {})

    rootState.user.$update({ filters })
    rootState.user.$apiSave().catch(() => {})
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
