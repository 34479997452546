// Mixin for som share functionality between components using tl-climbs:
// - tl-climbs-logging
// - tl-climbs-edit
// - tl-comp-climbs-select
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { filterClimbsInView } from '@/components/gym/climbs/map/map-utils'
import climbsStore from './climbs.store'
import Climb from '@/models/Climb'

export default {
  computed: {
    ...mapState(['isAdmin']),
    ...mapState('selection', ['selection', 'multiSelectMode']),
    ...mapState('climbs', { loadingClimbs: 'loading' }),
    ...mapState('climbs', ['view', 'setPositionMode']),
    ...mapGetters('selection', ['selected']),
    ...mapGetters('climbs', ['filteredClimbs']),
  },
  beforeCreate() {
    this.$store.registerModuleOnce('climbs', climbsStore)
  },
  created() {
    if (this.isAdmin) this.$store.dispatch('climbs/userFilters/updateFilters', {})
    this.$store.commit('selection/setMethodSelect', climb => {
      if ( // eslint-disable-line
        !this.selected(climb) &&
        !this.multiSelectMode &&
        (this.view == 'map' || this.view == 'hybrid')
      ) {
        return this.zoomToClimb(climb)
      } else {
        return Promise.resolve()
      }
    })
  },
  watch: {
    loadingClimbs(isLoading) {
      if (isLoading) return
      this.selectCurrentClimb()
    },
  },
  methods: {
    ...mapActions('selection', ['setSelection']),
    ...mapActions('selection', { clearSelection: 'clear' }),
    ...mapMutations('selection', ['setMultiSelectMode']),
    ...mapActions('climbs/climbsMap', ['zoomToClimb']),
    selectCurrentClimb() {
      if (this.$route.params.climbId) {
        const climb = Climb.find(this.$route.params.climbId)
        if (!climb) {
          return this.$router.pushSmart({
            name: this.$route.name.replace(/\.climb$/, ''),
            params: { climbId: null },
          })
        }
        this.setSelection([climb])
      } else {
        this.clearSelection()
      }
    },
    selectAllFiltered() {
      if (!this.filteredClimbs.length) return
      this.setMultiSelectMode(true)
      this.setSelection(this.filteredClimbs)
    },
    selectAllInView() {
      let bounds = this.$store.getters['climbs/climbsMap/visibleBounds']
      let climbsInView = filterClimbsInView(this.filteredClimbs, bounds)
      if (!climbsInView.length) return
      this.setMultiSelectMode(true)
      this.setSelection(climbsInView)
    },
  },
}
