<template>
  <v-list-item ripple @click.stop="">
    <v-list-item-action>
      <v-checkbox
        :value="selected"
        :value-comparator="comperator"
        :indeterminate="indeterminate"
        color="primary"
        @click.stop="toggleThreeState"
      />
    </v-list-item-action>
    <v-list-item-content @click="toggleThreeState">
      <v-list-item-title>{{ title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: null },
    title: { type: String, default: '' },
  },
  data: () => ({
    indeterminate: false,
  }),
  computed: {
    selected: {
      get: function() {
        return this.value
      },
      set: function(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  methods: {
    comperator() {
      return this.value != null
    },
    toggleThreeState() {
      if (this.selected == null) return (this.selected = true)
      if (this.selected == true) return (this.selected = false)
      else return (this.selected = null)
    },
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        // Handle some weird vuetify execution order bug...
        this.$nextTick(() => {
          this.indeterminate = this.selected == false
        })
      },
    },
  },
}
</script>
