<template>
  <v-list-item-avatar>
    <v-img v-if="avatar && !invalid" :src="avatar" @error="onImgError" />
    <v-icon v-else class="grey lighten-2 white--text">tl-person</v-icon>
  </v-list-item-avatar>
</template>

<script>
export default {
  props: {
    avatar: { type: String, default: null },
  },
  data: () => ({
    invalid: false,
  }),
  methods: {
    onImgError() {
      this.invalid = true
    },
  },
}
</script>
