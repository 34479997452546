<template>
  <v-list-item :light="!checks" :dark="!!checks" class="tl-list-climb" :class="classObj" :ripple="selectable">
    <v-list-item-action class="tl-group__grade-circle mr-4">
      <tl-climb-circle
        :color="climb.hold.color"
        :color-secondary="climb.hold.color_secondary"
        :grade="climb.grade"
        :grade-stability="climb.gradeStability"
        :checks="checks"
        :selected="isSelected"
        :loading="selecting(climb)"
      />
    </v-list-item-action>
    <v-list-item-content class="tl-list-climb__fadeable">
      <v-list-item-title>
        <v-icon v-if="climb.suitable_for_kids">tl-child_care</v-icon>
        {{ primaryInfo }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ secondaryInfo }}
        <tl-new-label v-if="climb.isNew" />
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="tl-list-climb__details tl-list-climb__fadeable">
      <v-list-item-action-text v-if="climb.nr_of_ascends">
        {{ climb.nr_of_ascends }}<v-icon small>tl-check</v-icon>
      </v-list-item-action-text>
      <v-list-item-action-text v-if="climb.average_opinion">
        {{ climb.average_opinion }}<v-icon small>tl-star</v-icon>
      </v-list-item-action-text>
      <v-list-item-action-text>
        <tl-date-removed :date="climb.date_removed" />
      </v-list-item-action-text>
    </v-list-item-action>
    <v-list-item-action v-if="sortable" class="ml-2" style="cursor: row-resize">
      <v-progress-circular indeterminate v-if="sorting" size="24" width="2" color="grey" />
      <v-icon v-else v-handle>tl-drag-vertical</v-icon>
    </v-list-item-action>
    <tl-not-live-label v-if="!climb.live" />
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import tlClimbCircle from '../tl-climb-circle'
import tlDateRemoved from './tl-date-removed'
import tlNewLabel from './tl-new-label'
import tlNotLiveLabel from '../edit/tl-not-live-label'
import { HandleDirective } from 'vue-slicksort'

export default {
  components: {
    tlClimbCircle,
    tlDateRemoved,
    tlNewLabel,
    tlNotLiveLabel,
  },
  directives: {
    handle: HandleDirective,
  },
  props: {
    climb: { type: Object, default: () => {} },
    showChecks: { type: Boolean, default: false },
    sortable: { type: Boolean, default: false },
    sorting: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('selection', ['selected', 'selecting']),
    checks() {
      if (!this.showChecks) return 0
      return this.climb.checks
    },
    isSelected() {
      return this.selectable && this.selected(this.climb)
    },
    classObj() {
      return {
        'tl-list-climb--checked': this.checks,
        'tl-list-climb--selected': this.isSelected,
        'tl-list-climb--fade': !this.climb.live,
      }
    },
    primaryInfo() {
      let props = [this.climb.displayName]
      if (this.climb.number != null) props.unshift(this.climb.number)
      return props.join(' ')
    },
    secondaryInfo() {
      let props = []
      if (this.climb.rope_number != null) props.push(`${this.$t('generic.rope')} ${this.climb.rope_number} `)
      if (this.climb.setter && this.climb.setter.name) props.push(this.climb.setter.name)
      if (this.climb.remarks) props.push(this.climb.remarks)
      return props.join(' - ')
    },
  },
}
</script>

<style lang="sass">
.tl-list-climb
  background-color: white
  &:hover::after
    @include tl-overlay
    pointer-events: none
    margin: 0 -16px
    background-color: rgba(0,0,0,0.05)
  &--selected
    background-color: var(--v-grey-lighten2)
  &--checked
    background-color: var(--v-grey-darken4)
    &:hover::after
      background-color: rgba(0,0,0,0.2)
    &.tl-list-climb--selected
      background-color: var(--v-grey-darken3)
  &--fade
    .tl-list-climb__fadeable
      opacity: 0.4
  &__details
    min-width: inherit
    // min-width inherit prevents showing it in a collapsed list.
    min-height: 54px
    .v-list-item__action-text
      min-height: 18px
</style>
