var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tl-climbs-table",attrs:{"loading":_vm.loading,"headers":_vm.enabledHeaders,"items":_vm.filteredClimbsSorted,"footer-props":{ itemsPerPageOptions: [20, 50, 100, -1] },"items-per-page":20,"mobile-breakpoint":0,"height":"100%","fixed-header":"","calculate-widths":"","show-select":"","value":_vm.selectionArr},on:{"input":_vm.setSelection,"click:row":_vm.toggleSelect},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [(_vm.selectingArr.length)?_c('v-progress-circular',{attrs:{"color":"primary","size":"20","width":"2","indeterminate":""}}):_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-simple-checkbox',props,false),on))]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [(_vm.selecting(item))?_c('v-progress-circular',{attrs:{"color":"primary","size":"20","width":"2","indeterminate":""}}):_c('v-simple-checkbox',{attrs:{"color":"primary","value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.checks",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":value ? 'success' : 'error'}},[_vm._v(_vm._s(value ? 'tl-check-circle' : 'tl-close-circle'))])]}},{key:"item.live",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":!value ? 'error' : ''}},[_vm._v(_vm._s(value ? 'tl-check' : 'tl-close'))])]}},{key:"item.hold.brand",fn:function(ref){
var item = ref.item;
return [_c('v-layout',[_c('tl-grade-circle',{attrs:{"color":item.hold.color,"color-secondary":item.hold.colorSecondary,"size":"20"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.hold.brand))])],1)]}},{key:"item.grade",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getGradeName(value))}})]}},{key:"item.grade_stability",fn:function(ref){
var value = ref.value;
return [_c('v-layout',[_c('tl-grade-circle',{attrs:{"color":"white","grade-stability":value,"size":"20"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(value))])],1)]}},{key:"item.auto_grade",fn:function(ref){
var value = ref.value;
return [_c('v-icon',[_vm._v(_vm._s(value ? 'tl-check' : 'tl-close'))])]}},{key:"item.average_opinion",fn:function(ref){
var value = ref.value;
return [_c('v-icon',[_vm._v("tl-star")]),_vm._v(_vm._s(value))]}},{key:"item.suitable_for_kids",fn:function(ref){
var value = ref.value;
return [_c('v-icon',[_vm._v(_vm._s(value ? 'tl-check' : 'tl-close'))])]}},{key:"item.renew",fn:function(ref){
var value = ref.value;
return [_c('v-icon',[_vm._v(_vm._s(value ? 'tl-check' : 'tl-close'))])]}},{key:"item.date_set",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.moment(value).format('ll')))]}},{key:"item.date_removed",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.moment(value).format('ll')))]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.moment(value).format('ll')))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }