<template>
  <div v-if="checks >= 1" class="tl-checks-circle" :class="classObj">
    <tl-check />
    <tl-check v-if="checks >= 2" />
    <tl-check v-if="checks == 3" />
  </div>
</template>

<script>
import tlCheck from './tl-check'

export default {
  components: {
    tlCheck,
  },
  props: {
    checks: { type: Number, default: 0 },
  },
  computed: {
    classObj() {
      return {
        one: this.checks == 1,
        two: this.checks == 2,
        three: this.checks == 3,
      }
    },
  },
}
</script>

<style lang="sass">
.tl-checks-circle
  background-color: var(--v-grey-darken4)
  border-radius: 22px
  position: absolute
  svg
    z-index: 1
    @include tl-center
    & + svg
      top: calc(50% - 4px)
      & + svg
        top: calc(50% + 4px)
  width: 18px
  height: 18px
  &.two
    width: 20px
    height: 20px
    svg
      top: calc(50% - 2px)
      & + svg
        top: calc(50% + 2px)
  &.three
    width: 22px
    height: 22px
</style>
