<template>
  <div class="tl-climb-edit d-flex flex-column">
    <v-toolbar absolute width="100%" class="tl-climb-edit__toolbar">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon :loading="deleting" :disabled="deleting" @click.stop="deleteClimbs">
        <v-icon>tl-delete</v-icon>
      </v-btn>
      <v-btn icon @click.stop="collapse">
        <v-icon>tl-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-toolbar flat />

    <v-banner v-if="noPosition" class="error" mobile-break-point="10000">
      <v-icon slot="icon" large>tl-map-marker-alert</v-icon>
      This climb doesn't have a position set on the map.
      <template v-slot:actions>
        <v-btn @click.stop="setPosition">Set position</v-btn>
      </template>
    </v-banner>

    <v-container fill-height px-6 class="d-flex flex-column align-stretch">
      <v-btn v-if="showOnMapBtn" block @click="setView('map')"><v-icon left>tl-map</v-icon>Show on map</v-btn>
      <v-subheader class="px-0">Color</v-subheader>

      <tl-selectable-color v-model="holdId" :holds="orderedHolds" required />

      <tl-selectable-group
        :value="commonGroupIds"
        :groups="groupOptions"
        :climb-type="climbType"
        multiple
        @input="updateGroups"
      />

      <v-text-field
        v-if="gym.edit_climb_show_number"
        :value="getCommonVal('number')"
        label="Number"
        @input="setCommonProp('number', $event)"
      />

      <v-text-field
        v-if="gym.rope_numbers && climbType == 'route'"
        type="number"
        :value="getCommonVal('rope_number')"
        label="Rope number"
        min="0"
        max="10000"
        @input="setCommonProp('rope_number', $event)"
      />

      <v-subheader class="px-0">Grade</v-subheader>
      <v-card outlined class="mb-4">
        <v-card-text class="pb-2">
          <tl-grade-slider
            :value="getCommonVal('grade')"
            :type="climbType"
            :disabled="autoGrade"
            small-icons
            class="my-2"
            @input="setCommonProp('grade', $event)"
          />

          <v-layout mx-0 mb-4 v-if="gym.show_grade_stability_admin" row justify-space-between align-center>
            <v-flex class="font-weight-medium">Stable?</v-flex>
            <v-btn-toggle v-model="gradeStabilityAdmin">
              <v-btn text height="36" :value="0.0" :disabled="autoGrade">No</v-btn>
              <v-btn text height="36" :value="0.5" :disabled="autoGrade">Semi</v-btn>
              <v-btn text height="36" :value="1.0" :disabled="autoGrade">Yes</v-btn>
            </v-btn-toggle>
          </v-layout>

          <v-layout mx-0 mb-4 v-if="gym.auto_grade" row justify-space-between align-center>
            <v-flex class="font-weight-medium">
              Auto grading
              <v-btn icon @click="showAutoGradeHelp"><v-icon>tl-info</v-icon></v-btn>
            </v-flex>
            <v-switch v-model="autoGrade" color="primary" hide-details class="mt-0" />
          </v-layout>

          <tl-grade-votes
            v-if="selection.length == 1"
            :grades-community="stats.community_grades"
            :grades-setters="stats.setter_grades"
            :loading="loadingStats"
            class="mb-2"
          />
          <div class="d-flex justify-end">
            <v-btn text v-if="anyGradeVotes" @click="showVoteStats = !showVoteStats">
              Votes
              <v-icon right>tl-chevron-{{ showVoteStats ? 'up' : 'down' }}</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-expand-transition>
          <tl-grade-vote-stats v-if="showVoteStats" :climb="selection[0]" class="py-0" />
        </v-expand-transition>
      </v-card>

      <v-text-field
        v-if="gym.edit_climb_show_name"
        type="text"
        :value="getCommonVal('name')"
        :label="nameLabel"
        @input="setCommonProp('name', $event)"
      />

      <v-select
        v-if="gym.edit_climb_show_setter"
        :value="getCommonVal('setter_id')"
        :items="setterOptions"
        item-text="name"
        item-value="id"
        label="Setter"
        @input="setCommonProp('setter_id', $event)"
      />

      <v-text-field
        v-if="gym.show_zones"
        type="number"
        :value="getCommonVal('zones')"
        label="Number of zones"
        min="0"
        max="20"
        @input="setCommonProp('zones', $event)"
      />

      <v-combobox
        v-if="gym.edit_climb_show_remarks"
        v-model="remarks"
        :items="remarksOptions"
        label="Remarks"
        multiple
      />

      <v-checkbox
        v-if="gym.show_for_kids"
        v-model="forKids"
        label="Suitable for children"
        color="primary"
        hide-details
        class="mt-0 mb-3"
      />

      <v-checkbox
        v-if="gym.renew"
        v-model="renew"
        label="Mark for renewal"
        color="primary"
        hide-details
        class="mt-0 mb-3"
      />

      <v-subheader class="d-flex justify-space-between px-0">
        <span>Dates</span>
        <v-btn icon small @click="showDatesHelp"><v-icon>tl-info</v-icon></v-btn>
      </v-subheader>
      <v-card outlined class="pb-0 mb-4">
        <v-card-text>
          <v-dialog ref="dialogDateSet" v-model="dateSetDialog" :return-value.sync="dateSet" width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="dateSet" label="Date set" prepend-icon="tl-event" readonly clearable v-on="on" />
            </template>
            <v-date-picker v-model="dateSet" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateSetDialog = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dialogDateSet.save(dateSet)">OK</v-btn>
            </v-date-picker>
          </v-dialog>

          <v-dialog ref="dialogDateRemoved" v-model="dateRemovedDialog" :return-value.sync="dateRemoved" width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRemoved"
                label="Expected removal"
                prepend-icon="tl-event"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker v-model="dateRemoved" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateRemovedDialog = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dialogDateRemoved.save(dateRemoved)">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-card-text>
      </v-card>

      <v-spacer class="fill-height" />

      <v-btn
        v-if="getCommonVal('live') !== true"
        :loading="togglingLive"
        :disabled="togglingLive"
        block
        large
        color="primary"
        class="mb-3"
        @click="toggleLive(true)"
      >
        Set live
        <v-icon right>tl-cloud-upload</v-icon>
      </v-btn>

      <v-btn
        v-if="getCommonVal('live') !== false"
        :loading="togglingLive"
        :disabled="togglingLive"
        block
        large
        class="mb-3"
        @click="toggleLive(false)"
      >
        Hide for users
        <v-icon right>tl-cloud-off</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { capitalize } from '@/services/utils'
import tlSelectableColor from '../shared/tl-selectable-color'
import tlSelectableGroup from '../shared/tl-selectable-group'
import tlGradeSlider from '@/components/shared/tl-grade-sliders/tl-grade-slider'
import tlGradeVotes from '../shared/tl-grade-votes'
import tlGradeVoteStats from '../shared/tl-grade-vote-stats'
import Climb from '@/models/Climb'
import ClimbGroup from '@/models/ClimbGroup'

export default {
  components: {
    tlSelectableColor,
    tlSelectableGroup,
    tlGradeSlider,
    tlGradeVotes,
    tlGradeVoteStats,
  },
  data: () => ({
    loadingStats: false,
    stats: {},
    showVoteStats: false,
    dateSetDialog: false,
    dateRemovedDialog: false,
    togglingLive: false,
    deleting: false,
  }),
  computed: {
    ...mapState(['gym', 'climbType']),
    ...mapState('selection', ['selection']),
    ...mapState('climbs', ['view']),
    climbsWord() {
      return this.climbType + (this.selection.length > 1 ? 's' : '')
    },
    title() {
      return `Edit ${this.climbsWord}`
    },
    noPosition() {
      return this.selection.length == 1 && (!this.selection[0].position_x || !this.selection[0].position_y)
    },
    showOnMapBtn() {
      return this.view != 'map' && this.view != 'hybrid' && this.selection.length == 1 && !this.noPosition
    },
    nameLabel() {
      return `${capitalize(this.climbsWord)} name`
    },
    orderedHolds() {
      return this.gym.holds.slice().sort((a, b) => a.order - b.order)
    },
    holdId: {
      get() {
        return this.getCommonVal('hold_id')
      },
      set(newVal) {
        this.setCommonProp('hold_id', newVal)
        this.saveNow() // Because setting circuit innediately after hold_id re-fetches the hold_id that is not saved yet.
      },
    },
    commonGroupIds() {
      // Get all group-ids and check for each id if there is a climb that has a climb_group with that group_id:
      let groupIds = this.selection.reduce((acc, climb) => acc.concat(climb.climb_groups), []).map(cg => cg.group_id)
      let groupIdsUniq = [...new Set(groupIds)]
      return groupIdsUniq.filter(groupId =>
        this.selection.every(climb => climb.climb_groups.some(climbGroup => climbGroup.group_id == groupId))
      )
    },
    getCommonVal() {
      return prop => {
        if (this.selection.length == 0) return null
        let initProp = this.selection[0][prop]
        if (this.selection.some(climb => !this.compareWithDate(climb[prop], initProp))) return null
        return initProp
      }
    },
    anyGradeVotes() {
      return (
        (this.stats.community_grades && this.stats.community_grades.length) ||
        (this.stats.setter_grades && this.stats.setter_grades.length)
      )
    },
    autoGrade: {
      get() {
        return this.getCommonVal('auto_grade')
      },
      set(newVal) {
        this.setCommonProp('auto_grade', newVal)
        this.saveNow()
      },
    },
    forKids: {
      get() {
        return this.getCommonVal('suitable_for_kids')
      },
      set(newVal) {
        this.setCommonProp('suitable_for_kids', newVal)
      },
    },
    renew: {
      get() {
        return this.getCommonVal('renew')
      },
      set(newVal) {
        this.setCommonProp('renew', newVal)
      },
    },
    gradeStabilityAdmin: {
      get() {
        let prop = this.autoGrade ? 'grade_stability' : 'grade_stability_admin'
        if (this.getCommonVal(prop) < 0.5) return 0.0
        if (this.getCommonVal(prop) < 1.0) return 0.5
        return 1.0
      },
      set(newVal) {
        this.setCommonProp('grade_stability_admin', newVal)
      },
    },
    remarksOptions() {
      const quickAdd = this.gym.remarks_quick_add || ''
      return quickAdd.includes(';') ? quickAdd.split(';') : [quickAdd]
    },
    remarks: {
      get() {
        let remarksStr = this.getCommonVal('remarks') || ''
        if (remarksStr.includes(', ')) return remarksStr.split(', ')
        if (remarksStr.length) return [remarksStr]
        return []
      },
      set(newVal) {
        this.setCommonProp('remarks', newVal.join(', '))
      },
    },
    groupOptions() {
      return this.gym.groups
        .filter(g => g.name)
        .filter(g => g.climbs_type == this.climbType)
        .slice()
        .sort((a, b) => a.order - b.order)
    },
    setterOptions() {
      let settersSorted = this.gym.setters
        .filter(s => !s.removed_at)
        .slice()
        .sort((a, b) => a.order - b.order)
      return [{ id: null, name: 'None' }].concat(settersSorted)
    },
    dateSet: {
      get() {
        let commonVal = this.getCommonVal('date_set')
        if (!commonVal) return null
        return new Date(commonVal).toISOString().substr(0, 10)
      },
      set(newVal) {
        let newDateFormatted = newVal ? new Date(newVal).toISOString().substr(0, 10) : null
        this.setCommonProp('date_set', newDateFormatted)
      },
    },
    dateRemoved: {
      get() {
        let commonVal = this.getCommonVal('date_removed')
        if (!commonVal) return null
        return new Date(commonVal).toISOString().substr(0, 10)
      },
      set(newVal) {
        let newDateFormatted = newVal ? new Date(newVal).toISOString().substr(0, 10) : null
        this.setCommonProp('date_removed', newDateFormatted)
      },
    },
  },
  watch: {
    selection: {
      immediate: true,
      handler() {
        this.fetchStats()
      },
    },
  },
  methods: {
    ...mapMutations('climbs', ['setView']),
    ...mapActions('nav-right', ['collapse']),
    ...mapActions('autosave', ['saveNow']),
    ...mapActions('selection', { clearSelection: 'clear' }),
    fetchStats() {
      this.stats = {}
      if (this.selection.length !== 1) return
      let climb = this.selection[0]
      if (climb.$isNew()) return

      this.loadingStats = true
      Climb.$apiCall('statsAdmin', { params: { gym_id: this.gym.id, climb_id: climb.id } })
        .then(stats => (this.stats = stats))
        .finally(() => (this.loadingStats = false))
    },
    async deleteClimbs() {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        title: `Remove ${this.selection.length} ${this.climbsWord}`,
        text: 'Are you sure?',
      })
      if (!confirmed) return

      this.deleting = true
      try {
        let ids = this.selection.map(climb => climb.id)
        this.clearSelection()
        await Climb.$apiCall('updateMany', {
          params: { gym_id: this.gym.id },
          injectResponse: true,
          data: {
            ids,
            climb: {
              deleted: true,
              date_deleted: new Date(),
              date_live_end: new Date(),
            },
          },
        })
        this.$store.dispatch('toast/success', `${capitalize(this.climbsWord)} removed`)
      } catch {
        this.$store.dispatch('toast/error', "Couldn't remove, please try again.")
      } finally {
        this.deleting = false
      }
    },
    setCommonProp(prop, val) {
      this.$store.commit('climbs/edit/setCacheProp', { prop, val })
      this.selection.forEach(climb => climb.$update({ [prop]: val }))
    },
    async updateGroups(newGroupIds) {
      let toDel = this.selection
        .reduce((acc, climb) => acc.concat(climb.climb_groups), [])
        .filter(cg => !newGroupIds.includes(cg.group_id))

      let toCreateDfds = this.selection.reduce((acc, climb) => {
        let presentGroupIds = climb.climb_groups.map(cg => cg.group_id) // Ids of all groups this climb is currently added to.
        let missingGroupIds = newGroupIds.filter(groupId => !presentGroupIds.includes(groupId))
        let newClimbGroups = missingGroupIds.map(groupId => {
          return ClimbGroup.inject({ climb_id: climb.id, group_id: groupId })
        })
        return acc.concat(newClimbGroups)
      }, [])
      let toCreate = await Promise.all(toCreateDfds) // Inject is async...

      // Destroy deselected:
      if (toDel.length) {
        toDel.forEach(cg => cg.$eject()) // Eager eject
        ClimbGroup.$apiCall('destroyMany', { data: { ids: toDel.map(cg => cg.id) } })
          .then(
            () => {},
            () => toDel.forEach(cg => ClimbGroup.inject(cg))
          )
          .finally(() => (this.removing = false))
      }

      // Create selected
      if (toCreate.length) {
        let removeEagerInjected = () => {
          toCreate.forEach(climbGroup => climbGroup.$eject())
        }
        ClimbGroup.$apiCall('createMany', {
          data: { climb_groups: toCreate },
          injectResponse: true,
          onSucces: data => {
            removeEagerInjected()
            return data
          },
        }).then(() => {}, removeEagerInjected)
      }

      let cachePropGroupId = toCreate.length ? toCreate[toCreate.length - 1].group_id : null
      this.$store.commit('climbs/edit/setCacheProp', { prop: 'group_id', val: cachePropGroupId })
    },
    compareWithDate(a, b) {
      if (a === b) return true
      if (a && b && a.constructor.name === 'Date' && b.constructor.name === 'Date' && a.getTime() === b.getTime())
        return true
      return false
    },
    showAutoGradeHelp() {
      const component = () => import('@/components/extras/help/autograde.vue')
      this.$store.dispatch('dialog/open', { component, props: { maxWidth: 800 } })
    },
    showDatesHelp() {
      const component = () => import('./dates-help.vue')
      this.$store.dispatch('dialog/open', { component, props: { maxWidth: 500 } })
    },
    toggleLive(newVal) {
      this.togglingLive = true
      let dfds = this.selection.map(climb => climb.toggleLive(newVal))
      Promise.all(dfds)
        .then(() => {
          if (newVal) {
            this.$store.dispatch('toast/success', `${capitalize(this.climbsWord)} live`)
            this.clearSelection() // Assume to be finished editing
          }
        })
        .catch(() => this.$store.dispatch('toast/error', 'Something went wrong, please try again.'))
        .finally(() => (this.togglingLive = false))
    },
    setPosition() {
      if (this.view != 'map') {
        this.setView('map')
      } else {
        this.$store.dispatch('climbs/climbsMap/zoomToElem') // Zoom to see full map.
      }
      this.$store.commit('climbs/toggleSetPositionMode', true)
      this.$store.dispatch('toast/info', 'Click the map to assign a position')
      this.collapse()
    },
  },
}
</script>

<style lang="sass">
.tl-climb-edit
  color: var(--v-grey-lighten1)
  &__toolbar
    border-left: 1px solid black !important
</style>
