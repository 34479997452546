<template>
  <div class="tl-climb-filters d-flex flex-column">
    <v-toolbar absolute width="100%">
      <v-toolbar-title>{{ $t('climbs.filters.filters') }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon :disabled="isDefault" @click.stop="resetFilters">
        <v-icon>tl-restore</v-icon>
      </v-btn>
      <v-btn icon @click.stop="collapse">
        <v-icon>tl-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-toolbar flat />
    <v-container fill-height class="d-flex flex-column align-stretch">
      <v-subheader class="flex-shrink-0">{{ $t('climbs.filters.grade') }}</v-subheader>
      <tl-grade-range-slider
        :value="filtersAll.grade"
        :type="climbType"
        class="px-6"
        @input="updateFilter('grade', $event)"
      />

      <div @touchstart.stop>
        <v-subheader>{{ $t('climbs.filters.starRating') }}</v-subheader>
        <v-range-slider
          :value="filtersAll.stars"
          :max="5"
          :step="0.5"
          color="primary"
          thumb-label="always"
          thumb-size="40"
          always-dirty
          hide-details
          class="pt-10 px-6"
          @end="updateFilter('stars', $event)"
        >
          <template v-slot:thumb-label="props">
            <span class="body-1" v-html="props.value" />
          </template>
        </v-range-slider>
      </div>

      <div>
        <v-subheader>{{ $t('climbs.filters.colors') }}</v-subheader>
        <tl-selectable-color
          :value="filtersAll.hold_ids"
          :holds="orderedHolds"
          multiple
          class="px-4"
          @input="updateFilter('hold_ids', $event)"
        />
      </div>

      <tl-selectable-group
        :value="filtersAll.group_ids"
        :groups="orderedGroups"
        :climb-type="climbType"
        multiple
        class="px-4"
        @input="updateFilter('group_ids', $event)"
      />

      <v-select
        :value="filtersAll.wall_ids"
        :items="orderedWalls"
        item-text="name"
        item-value="id"
        label="Walls"
        multiple
        chips
        deletable-chips
        clearable
        dense
        class="px-4"
        @input="updateFilter('wall_ids', $event)"
      />

      <v-list>
        <tl-filters-checkbox
          v-if="!isAdmin"
          :value="filtersAll.project"
          :title="$t('climbs.log.project')"
          @input="updateFilter('project', $event)"
        />
        <tl-filters-checkbox v-if="!isAdmin" v-model="notTopped" :title="$t('climbs.filters.todo')" />
        <tl-filters-checkbox
          :value="filtersAll.is_new"
          :title="$t('climbs.new')"
          @input="updateFilter('is_new', $event)"
        />
        <tl-filters-checkbox
          :value="filtersAll.almost_removed"
          :title="$t('climbs.filters.almostRemoved')"
          @input="updateFilter('almost_removed', $event)"
        />
        <tl-filters-checkbox
          v-if="gym.show_for_kids"
          :value="filtersAll.suitable_for_kids"
          :title="$t('climbs.filters.forChildren')"
          @input="updateFilter('suitable_for_kids', $event)"
        />
      </v-list>

      <v-spacer />
      <div>
        <v-btn block color="primary" :disabled="isDefault" @click="resetFilters">
          {{ $t('climbs.filters.clearFilters') }}
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import tlGradeRangeSlider from '@/components/shared/tl-grade-sliders/tl-grade-range-slider'
import tlSelectableColor from '../shared/tl-selectable-color'
import tlSelectableGroup from '../shared/tl-selectable-group'
import tlFiltersCheckbox from './tl-filters-checkbox'

export default {
  components: {
    tlGradeRangeSlider,
    tlSelectableColor,
    tlSelectableGroup,
    tlFiltersCheckbox,
  },
  computed: {
    ...mapState(['user', 'gym', 'climbType', 'isAdmin']),
    ...mapGetters('climbs/userFilters', ['filters', 'filtersDefault', 'isDefault']),
    filtersAll() {
      return Object.assign({}, this.filtersDefault, this.filters)
    },
    notTopped: {
      get: function() {
        return this.filtersAll.topped != null ? !this.filtersAll.topped : null
      },
      set: function(newVal) {
        let inverseVal = newVal != null ? !newVal : null
        this.updateFilter('topped', inverseVal)
      },
    },
    orderedHolds() {
      return this.gym.holds.slice().sort((a, b) => a.order - b.order)
    },
    orderedGroups() {
      return this.gym.groups
        .filter(g => g.name)
        .filter(g => g.climbs_type == this.climbType)
        .slice()
        .sort((a, b) => a.order - b.order)
    },
    orderedWalls() {
      return this.gym.walls.slice().sort((a, b) => a.order - b.order)
    },
  },
  methods: {
    ...mapActions('nav-right', ['collapse']),
    updateFilter(prop, val) {
      let filtersCopy = JSON.parse(JSON.stringify(this.filters))
      filtersCopy[prop] = val
      this.$store.dispatch('climbs/userFilters/updateFilters', filtersCopy)
    },
    resetFilters() {
      this.$store.dispatch('climbs/userFilters/updateFilters', {})
      this.collapse()
    },
  },
}
</script>
