<template>
  <foreignObject :x="position.x | percentize" :y="position.y | percentize" width="1" height="1">
    <div
      class="tl-climbs-map-climb"
      :class="classObj"
      v-hammer:press="onLongPress"
      v-hammer:pressup="onDragEnd"
      v-hammer:panstart="onDragStart"
      v-hammer:pan.all="onDragMove"
      v-hammer:panend="onDragEnd"
      v-hammer:pancancel="onDragEnd"
    >
      <tl-climb-circle
        :color="climb.hold.color"
        :color-secondary="climb.hold.color_secondary"
        :grade="climb.grade"
        :grade-stability="climb.gradeStability"
        :checks="checks"
        :number="climb.number"
        :selected="selected(climb)"
        :loading="selecting(climb)"
      />
      <v-icon v-if="alarm" class="tl-climbs-map-climb__alarm" :color="alarm.color">tl-alarm</v-icon>
      <span v-if="showName" class="tl-climbs-map-climb__name" :style="nameStyleObj">{{ climb.name }}</span>
      <span v-if="label" class="tl-climbs-map-climb__label" :class="label.class">{{ label.text }}</span>
    </div>
  </foreignObject>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { contrastingColor } from '@/services/utils'
import { getClickedWallId } from './map-utils'
import d3 from '@/services/d3-stripped'
import tlClimbCircle from '../tl-climb-circle'

export default {
  components: {
    tlClimbCircle,
  },
  filters: {
    percentize(value) {
      return ((value || 0) * 100.0).toFixed(4) + '%'
    },
  },
  props: {
    climb: { type: Object, default: () => {} },
    showChecks: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
  },
  data: () => ({
    position: { x: 0, y: 0 },
    positionDragInit: { x: 0, y: 0 },
    dragging: false,
  }),
  computed: {
    ...mapState(['gym']),
    ...mapState('climbs/climbsMap', ['transform']),
    ...mapGetters('climbs/climbsMap', ['zoomClimbs', 'wDrawing', 'hDrawing']),
    ...mapState('selection', ['multiSelectMode']),
    ...mapGetters('selection', ['selected', 'selecting']),
    checks() {
      return this.showChecks ? this.climb.checks : 0
    },
    showName() {
      return this.climb.name && this.transform.k > this.zoomClimbs * 2
    },
    nameStyleObj() {
      return {
        color: contrastingColor(this.climb.hold.color),
        'background-color': this.climb.hold.color,
      }
    },
    classObj() {
      return {
        'tl-climbs-map-climb--not-live': this.climb.live != true,
        'tl-climbs-map-climb--checks': this.checks > 0,
        'tl-climbs-map-climb--dragging': this.dragging,
      }
    },
    alarm() {
      if (this.climb.removedSooner) return { color: 'error' }
      if (this.climb.removedSoon) return { color: 'warning' }
      return null
    },
    label() {
      let baseClass = 'tl-climbs-map-climb__label'
      if (this.showName) baseClass = `${baseClass}--below-name ${baseClass}`
      if (this.climb.live != true) return { text: 'Not Live', class: `${baseClass}--not-live` }
      if (this.climb.isNew) return { text: this.$t('climbs.new'), class: `${baseClass}--new` }
      return null
    },
  },
  mounted() {
    let climbPos = { x: this.climb.position_x, y: this.climb.position_y }
    let wallPos = {
      x: this.climb.wall && this.climb.wall.label_x,
      y: this.climb.wall && this.climb.wall.label_y,
    }
    this.tweenPosition(wallPos, climbPos)
  },
  methods: {
    ...mapActions('autosave', ['addRecordsToSave', 'saveNow']),
    tweenPosition(oldPos, newPos) {
      let interpolator = d3.interpolateObject(oldPos, newPos)
      d3.select(this.$el)
        .transition()
        .duration(500)
        .tween('postion', () => t => (this.position = interpolator(t)))
    },
    onLongPress() {
      if (this.draggable) {
        this.dragging = true
        this.$store.commit('climbs/climbsMap/setZoomable', false)
      } else {
        this.$store.dispatch('selection/handleLongPress', this.climb)
      }
    },
    onDragStart() {
      this.positionDragInit = {
        x: +this.position.x,
        y: +this.position.y,
      }
    },
    onDragMove(e) {
      if (!this.dragging) return
      this.position.x = +this.positionDragInit.x + e.deltaX / this.wDrawing
      this.position.y = +this.positionDragInit.y + e.deltaY / this.hDrawing
    },
    onDragEnd(e) {
      this.dragging = false
      this.$store.commit('climbs/climbsMap/setZoomable', true)

      if (e.distance > 10) {
        this.addRecordsToSave([this.climb])
        this.climb.$update({
          position_x: this.position.x,
          position_y: this.position.y,
          wall_id: getClickedWallId({ event: e.srcEvent, gymId: this.gym.id }),
        })
        this.saveNow()
      } else if (this.draggable) {
        this.$store.dispatch('selection/handleLongPress', this.climb)
      }
    },
  },
}
</script>

<style lang="sass">
.tl-climbs-map-climb
  position: fixed
  transition: transform 300ms
  transform: translate(-50%, -50%)
  border-radius: 100%
  user-select: none
  &:after
    @include tl-overlay
    border-radius: 100%
    opacity: 0
    top: 0
    left: 0
    background-color: rgba(0,0,0,.3)
    transition: opacity 100ms
  &:hover:after
    opacity: 1
  &--not-live
    opacity: 0.5
  &--checks
    transform-origin: (-20px, -20px)
    transform: translate(-50%, -50%) scale(0.6)
    .tl-grade-circle
      opacity: 0.4
    .tl-checks-circle
      transform: translate(-60%, -60%) scale(1.6)
  &--dragging
    cursor: move
    &::before
      content: ''
      @include tl-center
      @include tl-square(90px, $radius: 100%)
      box-shadow: 0px 0px 20px 5px var(--v-primary-base), inset 0px 0px 20px 5px var(--v-primary-base)
      border: 2px solid white
      @keyframes grow
        0%
          width: 40px
          height: 40px
        60%
          width: 150px
          height: 150px
        100%
          width: 90px
          height: 90px
      animation: grow .3s ease-in
  &__name,
  &__label
    position: absolute
    left: 50%
    top: 88%
    transform: translateX(-50%)
    text-align: center
    padding: 1px 5px
    white-space: nowrap
  &__name
    border-radius: 6px
    box-shadow: 0 0 10px black
  &__label
    border-radius: 6px
    font-size: 12px
    color: white
    &--below-name
      top: 150%
    &--not-live
      opacity: 1
      background-color: red
    &--new
      background-color: var(--v-accent-base)
  &__alarm
    position: absolute
    right: 0
    top: 0
    transform: translate(40%, -40%)
    background-color: white
    width: 16px
    height: 16px
    border-radius: 100%
</style>
