<template>
  <v-lazy min-height="78">
    <tl-list-gestures
      ref="gesture"
      :icon-left="swipe.left.icon"
      :icon-right="swipe.right.icon"
      :text-left="swipe.left.text"
      :text-right="swipe.right.text"
      :swiping.sync="swiping"
      :disabled="!loggable"
      @gesture-left="swipe.left.action"
      @gesture-right="swipe.right.action"
    >
      <tl-list-climb
        :climb="climb"
        :show-checks="showChecks"
        :sortable="sortable"
        :sorting="sorting"
        :selectable="selectable"
        v-hammer:press="onLongPress"
        @click.native="onClick($event)"
      />
    </tl-list-gestures>
  </v-lazy>
</template>

<script>
import { mapGetters } from 'vuex'
import tlListGestures from '@/components/shared/tl-list-gestures'
import tlListClimb from './tl-list-climb'

export default {
  components: {
    tlListGestures,
    tlListClimb,
  },
  props: {
    climb: { type: Object, default: () => {} },
    showChecks: { type: Boolean, default: false },
    sortable: { type: Boolean, default: false },
    sorting: { type: Boolean, default: false },
    loggable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    teachSwipe: { type: Boolean, default: false },
  },
  data: () => ({
    swiping: false,
  }),
  computed: {
    ...mapGetters(['userViewed']),
    swipe() {
      let left, right
      if (this.climb.checks) {
        left = { icon: 'tl-remove', text: this.$t('climbs.log.repeat'), action: this.removeRepeat }
        right = { icon: 'tl-add', text: this.$t('climbs.log.repeat'), action: this.addRepeat }
      } else {
        left = { icon: 'tl-check', text: this.$t('climbs.log.top'), action: () => this.check(1) }
        let fl = { icon: 'tl-check-2', text: this.$t('climbs.log.flash'), action: () => this.check(2) }
        let os = { icon: 'tl-check-3', text: this.$t('climbs.log.onsight'), action: () => this.check(3) }
        right = this.climb.climb_type == 'boulder' ? fl : os
      }
      return { left, right }
    },
    checks() {
      return this.climb.checks
    },
    topsWatch() {
      if (!this.teachSwipe) return false
      return this.climb.tops
    },
  },
  watch: {
    topsWatch(newVal, oldVal) {
      let isRemove = newVal < oldVal
      let isRepeat = newVal > oldVal && oldVal
      let isMinChecks = newVal && !oldVal && this.climb.checks == 1
      let isMaxChecks = newVal && !oldVal && this.climb.checks == this.climb.maxChecks
      if (isMinChecks || isRemove) {
        this.$refs.gesture.triggerAnimation('left')
      } else if (isMaxChecks || isRepeat) {
        this.$refs.gesture.triggerAnimation('right')
      }
    },
  },
  methods: {
    onClick(event) {
      if (this.swiping) return
      if (!this.selectable) return
      this.$store.dispatch('selection/handleClick', { event, item: this.climb })
    },
    onLongPress() {
      if (this.swiping) return
      if (!this.selectable) return
      this.$store.dispatch('selection/handleLongPress', this.climb)
    },
    check(checks) {
      if (!this.userViewed) return
      this.userViewed.toggleCheck(this.climb, Infinity, checks)
    },
    addRepeat() {
      if (!this.userViewed) return
      this.userViewed.addRepeat(this.climb)
    },
    removeRepeat() {
      if (!this.userViewed) return
      this.userViewed.removeRepeat(this.climb)
    },
  },
}
</script>
