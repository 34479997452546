var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.groups.length)?_c('v-select',{attrs:{"value":_vm.value,"items":_vm.groups,"label":_vm.climbType == 'route' ? 'Group' : 'Circuit',"multiple":_vm.multiple,"item-text":"name","item-value":"id","clearable":"","chips":""},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
var parent = ref.parent;
return [_c('v-chip',{key:item.id,attrs:{"value":selected,"close":"","pill":""},on:{"click:close":function($event){return parent.selectItem(item)}}},[(item.hold)?_c('v-avatar',{attrs:{"left":""}},[_c('tl-climb-circle',{staticStyle:{"height":"100%"},attrs:{"color":item.hold.color,"color-secondary":item.hold.color_secondary}})],1):_c('v-icon',{staticClass:"mr-2",style:({ color: item.color })},[_vm._v("tl-label")]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},(_vm.groups.length > 1)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.$emit('input', [])}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":!_vm.value.length ? 'primary' : ''}},[_vm._v(" "+_vm._s(!_vm.value.length ? 'tl-check-box' : 'tl-check-box-outline-blank')+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("None")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(attrs.inputValue ? 'tl-check-box' : 'tl-check-box-outline-blank'))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1),_c('v-list-item-avatar',[(item.hold)?_c('tl-climb-circle',{staticStyle:{"height":"20px","width":"20px"},attrs:{"color":item.hold.color,"color-secondary":item.hold.color_secondary}}):_c('v-icon',{style:({ color: item.color }),attrs:{"large":""}},[_vm._v("tl-label")])],1)]]}}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }