<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <v-list>
      <template v-for="(opinion, index) in opinionsPaginated">
        <v-divider :key="index" />
        <v-list-item :key="opinion.id">
          <tl-list-item-avatar :avatar="opinion.user.anonymous ? '' : opinion.user.avatar" class="mr-4" />
          <v-list-item-content>
            <v-list-item-title>{{ getUserName(opinion.user) }}</v-list-item-title>
            <v-list-item-subtitle v-text="moment(opinion.date_graded).fromNow()" />
          </v-list-item-content>
          <v-list-item-action>{{ getGradeName(opinion.grade) }}</v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <tl-pagination-local :list="opinions" :items-per-page="10" @list-paginated="opinionsPaginated = $event" />
  </div>
</template>

<script>
import Climb from '@/models/Climb'
import Opinion from '@/models/Opinion'
import Grade from '@/services/grades/Grade'
import tlListItemAvatar from '@/components/shared/tl-list-item-avatar'
import tlPaginationLocal from '@/components/shared/tl-pagination-local'

export default {
  components: {
    tlListItemAvatar,
    tlPaginationLocal,
  },
  props: {
    climb: { type: Climb, default: () => {} },
  },
  data: () => ({
    loading: false,
    opinions: [],
    opinionsPaginated: [],
  }),
  created() {
    this.fetchStats()
  },
  methods: {
    fetchStats() {
      this.loading = true
      Opinion.$apiAll({
        injectResponse: false,
        params: {
          json_params: {
            filters: {
              climb_id: this.climb.id,
              date_graded: { null: false },
            },
            includes: ['user'],
          },
        },
      })
        .then(opinions => (this.opinions = opinions))
        .finally(() => (this.loading = false))
    },
    getUserName(user) {
      if (user.anonymous) return 'Anonymous user'
      return [user.first_name, user.last_name].join(' ')
    },
    getGradeName(grade) {
      return new Grade(grade).nameOrQStripped
    },
  },
}
</script>
