<template>
  <div class="tl-grade-range-slider" @touchstart.stop="">
    <v-range-slider
      :value="indices"
      :max="iMax"
      :readonly="disabled"
      color="primary"
      thumb-label="always"
      thumb-size="40"
      always-dirty
      hide-details
      class="pt-10"
      @change="indices = $event"
    >
      <template v-slot:thumb-label="props">
        <span class="body-1" v-html="gradeName(props.value)" />
      </template>
    </v-range-slider>
  </div>
</template>

<script>
import tlGradeSliderMixin from './tl-grade-slider.mixin'

export default {
  mixins: [tlGradeSliderMixin],
  props: {
    value: { type: Array, default: () => [0, 10] },
  },
  computed: {
    indices: {
      get() {
        let iMin = this.valueToIndex(this.value[0] || 0)
        let iMax = this.valueToIndex(this.value[1] || 10)
        return [iMin, iMax]
      },
      set(newIndices) {
        let minVal = this.indexToValue(newIndices[0])
        let maxVal = this.indexToValue(newIndices[1])
        this.$emit('input', [minVal, maxVal])
      },
    },
  },
}
</script>

<style lang="sass">
.tl-grade-range-slider
  .v-slider__thumb,
  .v-slider__thumb-label__container
    cursor: pointer
</style>
