<template>
  <v-select
    v-if="groups.length"
    :value="value"
    :items="groups"
    :label="climbType == 'route' ? 'Group' : 'Circuit'"
    :multiple="multiple"
    item-text="name"
    item-value="id"
    clearable
    chips
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="{ item, selected, parent }">
      <v-chip :value="selected" :key="item.id" close pill @click:close="parent.selectItem(item)">
        <v-avatar v-if="item.hold" left>
          <tl-climb-circle :color="item.hold.color" :color-secondary="item.hold.color_secondary" style="height: 100%" />
        </v-avatar>
        <v-icon v-else :style="{ color: item.color }" class="mr-2">tl-label</v-icon>
        {{ item.name }}
      </v-chip>
    </template>
    <template v-if="groups.length > 1" v-slot:prepend-item>
      <v-list-item ripple @click="$emit('input', [])">
        <v-list-item-action>
          <v-icon :color="!value.length ? 'primary' : ''">
            {{ !value.length ? 'tl-check-box' : 'tl-check-box-outline-blank' }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>None</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:item="{ item, attrs }">
      <template>
        <v-list-item-action>
          <v-icon>{{ attrs.inputValue ? 'tl-check-box' : 'tl-check-box-outline-blank' }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-avatar>
          <tl-climb-circle
            v-if="item.hold"
            :color="item.hold.color"
            :color-secondary="item.hold.color_secondary"
            style="height: 20px; width: 20px;"
          />
          <v-icon v-else :style="{ color: item.color }" large>tl-label</v-icon>
        </v-list-item-avatar>
      </template>
    </template>
  </v-select>
</template>

<script>
import tlClimbCircle from '../tl-climb-circle'

export default {
  components: {
    tlClimbCircle,
  },
  props: {
    value: { type: [Array, Number], default: () => [] },
    groups: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: false },
    climbType: { type: String, default: 'route' },
  },
}
</script>
