<template>
  <div class="fill-height">
    <div v-if="view != 'table'" class="tl-climbs" :class="{ 'tl-climbs--set-pos-mode': setPositionMode }">
      <v-overlay :value="loadingAny" z-index="1" class="tl-climbs__loading-overlay">
        <v-progress-circular size="50" color="primary" indeterminate />
      </v-overlay>

      <tl-climbs-map
        :show-checks="showChecks"
        :editable="editable"
        :height="view === 'map' ? '100%' : '30%'"
        v-tl-click-outside="e => $emit('map-click-outside', e)"
        v-resize="resizeHandler"
        @map-click="$emit('map-click', $event)"
      />

      <v-btn
        v-if="view == 'map' && !sideBySide"
        block
        tile
        class="tl-climbs__btn-show-list elevation-8"
        @click="setView('hybrid')"
      >
        {{ $t('climbs.showList') }}
      </v-btn>

      <v-fab-transition>
        <v-btn v-show="view == 'list'" color="primary" dark fixed bottom right fab @click="setView('map')">
          <v-icon>tl-map</v-icon>
        </v-btn>
      </v-fab-transition>

      <tl-swipable-bottom-sheet
        :state.sync="listState"
        :top-half="30"
        :inoperative="sideBySide"
        class="tl-climbs__list-container"
        :class="{ 'tl-climbs__list-container--mini': listMini }"
        ref="swipeableBottomSheet"
      >
        <v-layout v-if="loadingAny" class="tl-climbs__list__spinner" column align-center justify-center fill-height>
          <v-progress-circular size="50" color="primary" indeterminate />
        </v-layout>
        <tl-climbs-list
          v-if="showList"
          class="tl-climbs__list"
          :show-checks="showChecks"
          :loggable="loggable"
          :editable="editable"
          :mini="listMini"
          @click.native="listMini ? toggleListMini(!listMini) : ''"
        />
      </tl-swipable-bottom-sheet>
      <!-- TODO: Hide if nothing in list -->
      <v-btn
        v-if="sideBySide"
        max-width="35px"
        min-width="35px"
        color="white"
        class="tl-climbs__btn-list-mini pr-3"
        :class="{ 'tl-climbs__btn-list-mini--collapsed': listMini }"
        @click="toggleListMini()"
      >
        <v-icon>{{ `tl-chevron-${listMini ? 'right' : 'left'}` }}</v-icon>
      </v-btn>
    </div>
    <tl-climbs-table v-if="view == 'table'" />
  </div>
</template>

<script>
import climbsStore from './climbs.store'
import { mapState, mapGetters, mapMutations } from 'vuex'
import tlClimbsMap from './map/tl-climbs-map'
import tlClimbsList from './list/tl-climbs-list'
import tlClimbsTable from './edit/tl-climbs-table'
import tlClimbFilters from './filters/tl-climb-filters'
import tlSwipableBottomSheet from '@/components/shared/tl-swipable-bottom-sheet'

export default {
  components: {
    tlClimbsMap,
    tlClimbsList,
    tlClimbsTable,
    tlSwipableBottomSheet,
  },
  data: () => ({
    listMini: false,
  }),
  props: {
    showChecks: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    loggable: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    climbsDraggable: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('climbs', { loadingClimbs: 'loading' }),
    ...mapState('climbs', ['view', 'setPositionMode']),
    ...mapGetters('climbs', ['climbs', 'filteredClimbs']),
    loadingAny() {
      return this.loadingClimbs || this.loading
    },
    sideBySide() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    showList() {
      return (!this.loadingAny && this.sideBySide) || this.view != 'map'
    },
    listState: {
      get() {
        if (this.view == 'list') return 'open'
        if (this.view == 'map') return 'closed'
        return 'half'
      },
      set(newState) {
        if (newState == 'open') return this.setView('list')
        if (newState == 'closed') return this.setView('map')
        return this.setView('hybrid')
      },
    },
  },
  beforeCreate() {
    this.$store.registerModuleOnce('climbs', climbsStore)
    this.$store.dispatch('climbs/userFilters/updateFilters') // Assure filters version and gym_id
  },
  async created() {
    this.setView(this.$route.query.view || 'map')
    this.$store.commit('nav-right/setComponent', tlClimbFilters)
    await this.$store.dispatch('climbs/fetch')
  },
  methods: {
    ...mapMutations('climbs', ['setView']),
    resizeHandler() {
      let offsetLeft = 0
      if (this.$vuetify.breakpoint.mdAndUp) {
        offsetLeft = this.listMini ? 92 : 420
      }
      this.$store.commit('climbs/climbsMap/setZoomOffsetLeft', offsetLeft)
      if (this.$vuetify.breakpoint.mdAndUp && this.view != 'map') {
        this.setView('map')
      }
    },
    toggleListMini(newVal = undefined) {
      this.listMini = newVal == undefined ? !this.listMini : !!newVal
      this.resizeHandler()
    },
  },
}
</script>

<style lang="sass" scoped>
.tl-climbs
  height: 100%
  overflow: hidden
  position: relative
  &--set-pos-mode .tl-climbs-map
    cursor: crosshair
  &__btn-show-list
    position: absolute
    bottom: 0
  &__btn-list-mini
    position: absolute
    top: 26px
    left: 410px
    transition: left 0.3s
    &--collapsed
      left: 82px
  &__list__spinner
    padding-top: 40%
    padding-bottom: 40%
  @include media-breakpoint('md-and-up')
    &__loading-overlay
      padding-left: 420px
    &__list-container
      width: 400px
      transition: width 0.3s
      top: 2.5%
      max-height: 95%
      left: 16px
      border-radius: 6px
      &--mini
        width: 72px
        overflow: hidden
</style>
