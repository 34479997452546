<template>
  <div class="tl-list-gesture">
    <div v-if="active" class="tl-list-gesture__background grey lighten-4" ref="background">
      <div
        class="primary tl-list-gesture__ripple tl-list-gesture__ripple-left"
        :class="{ 'tl-list-gesture__ripple-left--active': this.leftActive }"
      />
      <div
        class="primary tl-list-gesture__ripple tl-list-gesture__ripple-right"
        :class="{ 'tl-list-gesture__ripple-right--active': this.rightActive }"
      />
      <div class="tl-list-gesture__btns d-flex flex-no-wrap align-center" :style="btnsStyle">
        <div v-show="!rightActive">
          <v-icon class="ml-4 pa-4" v-text="btnsLazy.iconLeft" ref="iconLeft" />
          <span v-text="btnsLazy.textLeft" />
        </div>
        <v-spacer />
        <div v-show="!leftActive">
          <span v-text="btnsLazy.textRight" />
          <v-icon class="mr-4 pa-4" v-text="btnsLazy.iconRight" ref="iconRight" />
        </div>
      </div>
    </div>
    <div
      class="tl-list-gesture__container"
      :class="{ 'tl-list-gesture__container--animating': animating, 'elevation-4': active }"
      ref="list-item"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs'

export default {
  props: {
    iconLeft: { type: String, default: '' },
    textLeft: { type: String, default: '' },
    iconRight: { type: String, default: '' },
    textRight: { type: String, default: '' },
    threshold: { type: Number, default: 150 },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    listItem: null,
    active: false,
    animating: false,
    leftActive: false,
    rightActive: false,
    btnsLazy: null,
    resetTimer: null,
  }),
  computed: {
    btnsStyle() {
      return { color: this.leftActive || this.rightActive ? 'white' : 'black' }
    },
    btns() {
      return {
        iconLeft: this.iconLeft,
        textLeft: this.textLeft,
        iconRight: this.iconRight,
        textRight: this.textRight,
      }
    },
  },
  created() {
    this.btnsLazy = this.btns
  },
  mounted() {
    this.listItem = this.$refs['list-item']
    this.listItem.style.left = 0
    if (!this.disabled) this.addGesture()
  },
  methods: {
    addGesture() {
      const mc = new Hammer(this.listItem)
      mc.on('panstart', e => {
        let moreYMove = Math.abs(e.deltaY) > Math.abs(e.deltaX)
        let moreYSpeed = Math.abs(e.velocityY) > Math.abs(e.velocityX)
        if (moreYMove || moreYSpeed) return
        clearTimeout(this.resetTimer)
        this.active = true
        this.animating = false
        this.$emit('update:swiping', true)
      })
      mc.on('panmove', e => {
        if (!this.active) return
        this.listItem.style.left = `${e.deltaX}px`
        this.leftActive = e.deltaX > this.threshold
        this.rightActive = e.deltaX < -this.threshold
      })
      mc.on('panend pancancel', e => {
        if (!this.active) return
        if (e.deltaX > this.threshold) {
          this.triggerAnimation('left')
          this.$emit('gesture-left')
        } else if (e.deltaX < -this.threshold) {
          this.triggerAnimation('right')
          this.$emit('gesture-right')
        } else {
          this.triggerAnimation()
        }
      })
    },
    triggerAnimation(dir) {
      this.active = true // Required in case the animation is triggered programatically
      this.animating = true
      this.$nextTick(() => {
        if (dir) {
          this.listItem.style.left = dir == 'right' ? '-700px' : '700px'
          this[`${dir}Active`] = true
        } else {
          this.listItem.style.left = 0
        }
      })
      this.resetTimer = setTimeout(this.reset, 1000)
    },
    reset() {
      clearTimeout(this.resetTimer)
      this.animating = false
      this.btnsLazy = this.btns
      this.$emit('update:swiping', false)
      this.$nextTick(() => {
        this.listItem.style.left = 0
        this.active = false
        this.leftActive = false
        this.rightActive = false
      })
    },
  },
}
</script>

<style lang="sass">
.tl-list-gesture
  overflow: hidden
  position: relative
  &__background
    position: absolute
    width: 100%
    height: 100%
  &__ripple
    position: absolute
    height: 1300px
    width: 1300px
    border-radius: 100%
    transition: transform 300ms cubic-bezier(.75,0,1,.25)
    top: 50%
    &-left
      left: 44px
      transform: translate(-50%, -50%) scale(0)
      &--active
        transform: translate(-50%, -50%) scale(1)
    &-right
      right: 44px
      transform: translate(50%, -50%) scale(0)
      &--active
        transform: translate(50%, -50%) scale(1)
  &__btns
    position: relative
    height: 100%
    .v-icon
      color: inherit
  &__container
    background-color: white
    position: relative
    cursor: pointer
    transition: none
    &--animating
      transition: left 600ms ease-out
</style>
