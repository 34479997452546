<template>
  <tl-climbs editable @map-click="onMapClick" @map-click-outside="toggleSetPositionMode(false)" />
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Climb from '@/models/Climb'
import ClimbGroup from '@/models/ClimbGroup'
import Group from '@/models/Group'
import climbsTypeGuard from '../climbs-type.guard'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import climbsRouteMixin from '@/components/gym/climbs/tl-climbs-route.mixin'
import climbsEditStore from './climbs-edit.store'
import tlClimbFilters from '../filters/tl-climb-filters'
import tlClimbEdit from './tl-climb-edit'
import tlClimbs from '../tl-climbs'

export default {
  mixins: [climbsRouteMixin, toolbarMixin],
  components: {
    tlClimbs,
  },
  computed: {
    ...mapState(['user', 'gym', 'climbType']),
    ...mapState('climbs', ['view', 'setPositionMode']),
    tlToolbarBtnLeft() {
      if (this.selection.length) return { icon: 'tl-arrow-back', action: this.clearSelection }
      return null
    },
    tlToolbarDark() {
      return this.selection.length
    },
    tlToolbarTitle() {
      if (this.setPositionMode) {
        if (this.selection.length) {
          return `Set position`
        } else {
          return `Adding ${this.climbType}`
        }
      } else if (this.selection.length) {
        return `${this.selection.length} selected`
      } else {
        return `Edit ${this.climbType}s`
      }
    },
    tlToolbarButtons() {
      if (this.selection.length) {
        return [
          {
            icon: 'tl-edit',
            action: this.toggleSideNav,
          },
          {
            text: 'Generate printable labels',
            action: this.makeLabelsPdf,
          },
        ]
      }

      if (this.setPositionMode) {
        return [
          {
            icon: 'tl-close',
            text: 'Cancel',
            action() {},
          },
        ]
      }

      return [
        {
          icon: 'tl-add',
          text: `Add ${this.climbType}`,
          action: this.startAddClimbMode,
        },
        {
          icon: 'tl-tune',
          iconBadge: !this.$store.getters['climbs/userFilters/isDefault'] ? 'tl-priority-high' : false,
          text: 'Filters',
          action: this.toggleSideNav,
        },
        {
          text: 'Select all filtered',
          action: this.selectAllFiltered,
        },
        {
          text: 'Select all in view',
          action: this.selectAllInView,
          hidden: this.view == 'list' || this.view == 'table',
        },
        {
          text: `${this.view == 'table' ? 'Map' : 'Table'} view`,
          action: this.toggleTableView,
        },
        {
          text: 'Help',
          action: () => this.showHelpDialog(),
        },
      ]
    },
  },
  beforeCreate() {
    this.$store.registerModuleOnce(['climbs', 'edit'], climbsEditStore)
  },
  created() {
    if (!this.user.storageState.hideInitialEditorHelp) this.showHelpDialog(true)
  },
  beforeRouteEnter: climbsTypeGuard,
  beforeRouteUpdate: climbsTypeGuard,
  watch: {
    selection: {
      immediate: true,
      handler() {
        this.addRecordsToSave(this.selection)
        // Toggle opening the editor
        if (this.selection.length) {
          this.$store.commit('nav-right/setComponent', tlClimbEdit)
          let isMultiSelect = this.selection.length > 1 || this.multiSelectMode
          let isTableView = this.view == 'table'
          let itFits = this.$vuetify.breakpoint.lgAndUp
          if ((!isMultiSelect && !isTableView) || itFits) {
            this.$store.dispatch('nav-right/expand')
          }
        } else {
          this.$store.dispatch('nav-right/collapse')
          this.$store.commit('nav-right/setComponent', tlClimbFilters)
        }
      },
    },
  },
  methods: {
    ...mapMutations('climbs', ['setView', 'toggleSetPositionMode']),
    ...mapActions('selection', { clearSelection: 'clear' }),
    ...mapActions('selection', ['toggleSelect']),
    ...mapActions('autosave', ['addRecordsToSave']),
    toggleTableView() {
      let newView = this.view != 'table' ? 'table' : 'map'
      this.setView(newView)
    },
    startAddClimbMode(e) {
      e.stopPropagation()
      this.toggleSetPositionMode(true)
      this.setView('map')
      this.$store.dispatch('toast/info', `Click the map to add your ${this.climbType}`)
    },
    onMapClick(clicked) {
      if (!this.setPositionMode) return
      this.$store.commit('toast/hide')
      this.toggleSetPositionMode(false)
      let positionProps = {
        position_x: clicked.coords.x,
        position_y: clicked.coords.y,
        wall_id: clicked.wallId,
      }

      if (this.selection.length) {
        this.selection[0].$update(positionProps)
        this.selection[0].$apiSave()
      } else {
        this.addClimb(positionProps)
      }
    },
    addClimb({ position_x, position_y, wall_id }) {
      let firstHold = this.gym.holds.slice().sort((a, b) => a.order - b.order)[0] || {}
      let newClimb = {
        gym_id: this.gym.id,
        climb_type: this.climbType,
        position_x,
        position_y,
        wall_id,
        hold_id: firstHold.id,
        grade: 2.0,
        auto_grade: this.gym.auto_grade,
        grade_stability_admin: 1.0,
        date_set: new Date(),
      }
      // Override defaults with cached when present:
      let propCache = this.$store.state.climbs.edit.propCache
      Object.keys(propCache).forEach(prop => {
        newClimb[prop] = propCache[prop]
      })
      // If number, push to cache to trigger auto-increment
      if (newClimb['number'] !== undefined) {
        this.$store.commit('climbs/edit/setCacheProp', { prop: 'number', val: newClimb['number'] })
      }

      Climb.inject(newClimb).then(newClimb => {
        // Add group when valid cached group_id:
        if (propCache.group_id) {
          let group = Group.find(propCache.group_id)
          if (group && group.climbs_type == this.climbType) {
            ClimbGroup.inject({ climb_id: newClimb.id, group_id: propCache.group_id })
          }
        }
        this.toggleSelect(newClimb)
        newClimb.$apiSave().then(() => {
          // Wait for the right climb_id to arive before saving the climbGroup.
          newClimb.climb_groups.forEach(cg => cg.$apiSave())
        })
      })
    },
    toggleSideNav() {
      this.$store.commit('nav-right/toggle')
    },
    async showHelpDialog(showDontShowAgain = false) {
      let props = {
        title: 'Climbs-editor help',
        ok: 'Got it',
        component: () => import('./tl-climbs-edit-help.vue'),
        componentProps: { showDontShowAgain },
        maxWidth: 800,
      }
      if (showDontShowAgain) props.cancel = "Don't show again"
      let dontShowAgain = (await this.$store.dispatch('dialog/alert', props)) == undefined
      if (dontShowAgain) this.user.setStorageStateProp('hideInitialEditorHelp', true)
    },
    makeLabelsPdf() {
      this.$store.dispatch('dialog/alert', {
        component: () => import('./tl-climbs-edit-print-labels.vue'),
        componentProps: { climbs: this.selection },
        maxWidth: 1048,
        title: 'Label printer',
        ok: 'Close',
      })
    },
  },
}
</script>
