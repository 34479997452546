import Climb from '@/models/Climb'
import ClimbGroup from '@/models/ClimbGroup'
import Group from '@/models/Group'
import Ascend from '@/models/Ascend'
import Opinion from '@/models/Opinion'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import userFiltersModule from './filters/user-filters.store'
import viewFiltersModule from './filters/view-filters.store'

const modules = {
  userFilters: userFiltersModule,
  viewFilters: viewFiltersModule,
}

const state = () => ({
  loading: false,
  view: 'map',
  checksFunc: null,
  setPositionMode: false,
})

const getters = {
  climbFilter(state, getters) {
    // eslint-disable-next-line
    return climb => (
      getters['viewFilters/climbFilter'](climb) &&
      getters['userFilters/climbFilter'](climb)
    )
  },
  climbs(state, getters, rootState) {
    return rootState.gym.climbs.filter(getters['viewFilters/climbFilter'])
  },
  filteredClimbs(state, getters, rootState) {
    return rootState.gym.climbs.filter(getters.climbFilter)
  },
  groups(state, getters, rootState) {
    // eslint-disable-next-line
    return rootState.gym.groups.filter(group =>
          group.climbs_type == rootState.climbType &&
          (rootState.isAdmin || group.live)
      )
      .sort((a, b) => a.order - b.order)
  },
  grouplessClimbs(state, getters, rootState) {
    const climbIds = getters.groups.reduce((acc, group) => acc.concat(group.climb_groups.map(cg => cg.climb_id)), [])
    // eslint-disable-next-line
    return rootState.gym.climbs.filter(climb =>
        !climbIds.includes(climb.id) &&
        getters['viewFilters/climbFilter'](climb)
      )
      .sort((a, b) => (a.number || 1000) - (b.number || 1000) || a.grade - b.grade)
  },
  grouplessClimbsFiltered(state, getters) {
    return getters.grouplessClimbs.filter(getters['userFilters/climbFilter'])
  },
}

const mutations = {
  setLoading(state, newVal) {
    state.loading = !!newVal
  },
  setView(state, newView) {
    if ((state.view == 'list' || state.view == 'hybrid') && vuetify.framework.breakpoint.mdAndUp) {
      newView = 'map'
    }
    state.view = newView
    // Reflect view in query params:
    const viewUrl = newView == 'map' ? undefined : newView
    router.replaceSmart({ query: { view: viewUrl } })
  },
  setChecksFunc(state, checksFunc) {
    state.checksFunc = checksFunc
  },
  toggleSetPositionMode(state, newVal) {
    state.setPositionMode = !!newVal
  },
}

const actions = {
  fetch({ commit, dispatch }) {
    commit('setLoading', true)
    Climb.ejectAll()
    Group.ejectAll()
    ClimbGroup.ejectAll()
    return Promise.all([dispatch('fetchClimbs'), dispatch('fetchGroups')]).finally(() => commit('setLoading', false))
  },
  fetchClimbs({ rootState }) {
    const params = {
      gym_id: rootState.gym.id,
      json_params: {
        filters: {
          deleted: false,
        },
      },
    }
    if (!rootState.isAdmin) params.json_params.filters.live = true
    return Climb.$apiAll({ params })
  },
  fetchGroups({ rootState }) {
    const filters = {
      gym_id: rootState.gym.id,
      score_system: 'none',
    }
    if (!rootState.isAdmin) filters.live = true
    return Group.$apiAll({ params: { json_params: { filters, includes: ['climb_groups'] } } })
  },
  kioskReset({ state, dispatch, commit }) {
    if (state.view != 'map') commit('setView', 'map')
    dispatch('climbsMap/kioskDemoZoom')
    dispatch('userFilters/updateFilters', {})
    dispatch('selection/clear', null, { root: true })
    dispatch('dialog/close', null, { root: true })
    dispatch('nav/collapse', null, { root: true })
    dispatch('nav-right/collapse', null, { root: true })
    Ascend.ejectAll()
    Opinion.ejectAll()
  },
}

export default {
  namespaced: true,
  modules,
  state,
  getters,
  mutations,
  actions,
}
