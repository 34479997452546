<template>
  <span
    v-if="dateRemovedText"
    class="tl-date-removed text-no-wrap"
    :class="{ 'tl-date-removed--soon': removedSoon, 'tl-date-removed--sooner': removedSooner }"
  >
    {{ dateRemovedText }} <v-icon size="14">tl-delete</v-icon>
  </span>
</template>

<script>
import { mapState } from 'vuex'
import { capitalize } from '@/services/utils'

export default {
  props: {
    date: { type: String, default: undefined },
  },
  computed: {
    ...mapState(['gym']),
    ...mapState(['isAdmin']),
    dateRemovedText() {
      if (!this.date) return ''

      const momentRemoved = this.moment(this.date)
      const today = this.moment().startOf('day')

      if (momentRemoved.isBefore(today) && !this.isAdmin) {
        return ''
      } else if (momentRemoved.startOf('day').isSame(today, 'day')) {
        return this.$t('generic.today')
      } else if (momentRemoved.startOf('day').isSame(today.add(1, 'days'), 'day')) {
        return this.$t('generic.tomorrow')
      } else {
        return capitalize(momentRemoved.fromNow())
      }
    },
    removedSoon() {
      return this.gym.removedSoon(this.date) && (!this.gym.removedExpired(this.date) || this.isAdmin)
    },
    removedSooner() {
      return this.gym.removedSooner(this.date) && (!this.gym.removedExpired(this.date) || this.isAdmin)
    },
  },
}
</script>

<style lang="sass">
.tl-date-removed
  &--soon
    &, .v-icon
      color: orange
  &--sooner
    &, .v-icon
      color: red
</style>
